import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import LoadGif from "./Image-Load/loading8.svg";
import PropTypes from "prop-types";
import "./Loading.scss";
export default function App({ textColor }) {
  const [isLoding, setIsLoding] = useState(true);
  const [tColor, setTColor] = useState(textColor ? textColor : "#12349F");

  useEffect(() => {}, []);

  return (
    <div className="App">
      {isLoding ? (
        <>
          <LoadingScreen
            loading={true}
            // bgColor="#f1f1f1"
            spinnerColor="#ffffff"
            textColor={tColor ? tColor : "#ffffff"}
            // logoSrc={spinner}
            // logoSrc="https://loading.io/icon/jr8r2s"
            logoSrc={LoadGif}
            // text="กำลังอัพเดตข้อมูล..."
          />
          <div className="overlay" />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
App.propTypes = {
  textColor: PropTypes.string,
};
