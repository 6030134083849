import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import "./input.css";

const InputData = ({
  type,
  className,
  id,
  placeholder,
  value,
  onChange,
  disable,
}) => {
  return (
    <input
      type={type}
      className={className}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disable ? true : false}
    />
  );
};

// Add PropTypes validation for the component props
InputData.propTypes = {
  type: PropTypes.string.isRequired, // 'type' is required and should be a string
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired, // onChange is required and should be a function
  disable: PropTypes.bool, // 'disable' should be a boolean
};

export default InputData;
