import { schema } from "./schema"

export const resetPasswordValidator = (formData) => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (!error) return '';

    const errorDetails = {};
    error.details.forEach((detail) => {
        errorDetails[detail.path[0]] = detail.message || '';
    });
    return errorDetails;
};