import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import "./../components/css/loginStyle.css";
import { loginService } from "../api/adminAuthRoutes";
import Loading from "../components/loading/Loading";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "../components/swalStyle/customStyle.css";
import { setTimeOutSync } from "./../utils/setTimeoutLoading";
import {
  textLoginBtn,
  colorLoginBtn,
  hoverLoginBtn,
} from "../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../components/useDynamic/Button_DynamicStyle";
import { textPrimary, textSecond } from "../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../components/useDynamic/text_DynamicStyle";
import { loginValidator } from "../validations/login/loginValidator";
import CustomSweetAlert from "../components/Sweetalert/index";
const buttonConfig = {
  textLoginBtn: textLoginBtn,
  colorLoginBtn: colorLoginBtn,
  hoverLoginBtn: hoverLoginBtn,
};
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
function Login() {
  const { t, i18n } = useTranslation();
  useButtonDynamicStyles(buttonConfig);
  useTextDynamicStyles(textConfig);
  const navigate = useNavigate();

  const [inputPassword, setInputpassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [inputUser, setInputuser] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorUser, setErrorUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("edit");
  console.log("errorUser data :  ", errorUser);
  const [errorPassword, setErrorPassword] = useState(null);
  const handleUserChange = (e) => {
    setInputuser(e.target.value);
    const result = loginValidator({
      username: e.target.value,
    });
    setErrorUser(result);
  };

  const handlePasswordChange = (e) => {
    setInputpassword(e.target.value);
    const result = loginValidator({
      password: e.target.value,
    });
    console.log("result data password : ", result);
    setErrorPassword(result);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser();
  };

  const save_Datauser = (tokenBackoffice) => {
    if (checked) {
      let dataarray = [];
      dataarray.push({
        username: inputUser,
        password: inputPassword,
      });
      localStorage.setItem("SaveLogin", JSON.stringify(dataarray));
      localStorage.setItem("tokenBackoffice", tokenBackoffice);
    }
  };

  const loginUser = async () => {
    localStorage.removeItem('wuuid')
    const data = {
      username: inputUser,
      password: inputPassword,
    };
    console.log("data user  : ", data);

    try {
      // ------
      setLoading(true);
      setTimeOutSync(() => setLoading(false)).then(async () => {
        // const respon = await login(data);
        // loginService
        const respon = await loginService(data);
        console.log("ELECTROLUX ETM3400L-B  : ", respon);
        if (respon && respon.code === "20000") {
          const workspaceId = localStorage.getItem("wuuid");
          localStorage.setItem("tokenBackoffice", respon.data.token);
          sessionStorage.setItem("tokenBackoffice", respon.data.token);
          save_Datauser(respon.data.token);
          if (workspaceId) {
            navigate("/workspace/" + workspaceId);
          } else {
            navigate("/infoDashboard");
          }
        } else {
          setShowAlert(true);
          // Swal.fire({
          //   icon: "error",
          //   title: t("login.error_case.email_or_password_mistake"),
          //   confirmButtonText: t("confirm"),
          // });
        }
      });
      // ---
    } catch (error) {
      console.log(error);
      setLoading(false);
      // Swal.fire({
      //   icon: "error",
      //   title: t("login.error_case.email_or_password_mistake"),
      //   confirmButtonText: t("confirm"),
      // });
    }
    await setLoading(false);
  };
  const handleConfirm = (status) => {
    console.log(status); // Handle the confirmation
    setShowAlert(false); // Hide the alert after confirmation
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
    console.log(status); // Handle the cancellation
    setShowAlert(false); // Hide the alert after cancellation
  };
  useEffect(() => {
    if (localStorage.getItem("SaveLogin")) {
      const saveLogindata = localStorage.getItem("SaveLogin");
      const setDatalogin = JSON.parse(saveLogindata);
      setInputuser(setDatalogin[0].username);
      setInputpassword(setDatalogin[0].password);
      setChecked(true);
    }
  }, []);

  return (
    <>
      {" "}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel: false,
            reverseButtons: true,
            confirmBtnText: "Yes",
            cancelBtnText: "Cancel",
            icon: "error",
            title: "WARNING",
            message: t("login.error_case.email_or_password_mistake"),
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder: "#E32A29",
            colorBtnConfirm: "#E32A29",
            colorBtnCancel: "#E0E0E0",
          }}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={(e) => handleSubmit(e)}>
          {" "}
          <div id="login">
            <div className="container-fluid height100">
              <div className="row justify-content-center height100">
                <div className="col-12 col-lg-6 login-template-color">
                  <div className="row justify-content-center fontLight text-white text-start  px-5">
                    {/* <h1 className="font-weight-bold text-title">
                      Content Management System
                    </h1>
                     */}
                  </div>
                  <div className="row "></div>
                </div>
                <div className="col-12 col-lg-6 login-template-text">
                  <div className="row area01">
                    <div className="col-12 mt-3 mb-3 d-flex justify-content-center">
                      <h4 className="font-weight-bold text-main">
                        เข้าสู่ระบบ
                      </h4>
                    </div>
                    <div className="sty-box01">
                      <div className="col-12">
                        <label>{t("login.username")}</label>
                        <input
                          value={inputUser}
                          onChange={handleUserChange}
                          type="text"
                          className="inp-login  "
                          id="user_name"
                          placeholder={t("login.username")}
                        // autoComplete={"off"}
                        />
                        <p
                          className={
                            errorUser?.username
                              ? "fs-14 mt-1 text-danger font-weight-bold"
                              : "d-none"
                          }
                        >
                          {errorUser?.username}
                          {inputUser === "" && inputPassword === ""
                            ? ""
                            : "ที่ email"}
                        </p>
                      </div>{" "}
                      <div className="col-12 mt-3">
                        <label>{t("login.password")}</label>
                        <div className="d-flex justify-content-between box-pass">
                          <input
                            value={inputPassword}
                            onChange={handlePasswordChange}
                            maxLength={16}
                            type={visible ? "text" : "password"}
                            className="inp-pass"
                            id="password"
                            placeholder={t("login.password")}
                          />
                          <div
                            className="p-2"
                            onClick={() => setVisible(!visible)}
                          >
                            {visible ? (
                              <i className="fa fa-eye"></i>
                            ) : (
                              <i className="fa fa-eye-slash"></i>
                            )}
                          </div>
                        </div>
                        <p
                          className={
                            errorPassword?.password
                              ? "fs-14 mt-1 text-danger font-weight-bold"
                              : "d-none"
                          }
                        >
                          {errorPassword?.password}{" "}
                          {inputUser === "" && inputPassword === ""
                            ? ""
                            : "ที่ password"}
                        </p>
                      </div>
                      <div className="row m-0">
                        <div className="col-6 mt-3">
                          <label className="checkbox-item01 fs-14  d-flex align-items-center">
                            {t("login.remember")}
                            <input
                              type="checkbox"
                              defaultChecked={checked}
                              checked={checked}
                              onChange={() => {
                                // console.log("checked : ", checked);
                                setChecked(!checked);
                                // if (checked === true) {
                                //   localStorage.removeItem("SaveLogin");
                                // }
                              }}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="col-6 mt-3 d-flex justify-content-end">
                          <Link
                            as={Link}
                            to="/forgotPassword"
                            className="text-main font-weight-bold "
                          >
                            {t("login.forgot_password")}
                          </Link>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-login fs-15  font-weight-bold "
                          disabled={
                            errorUser?.username || errorPassword?.password
                          }
                        >
                          {t("login.submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Login;
