export const imgExport = "/assets/icons/ic-export.png";
export const colortExpBtn = "#00A8A9";
export const colorBorExpBtn = "#00A8A9";
export const hoverExpBtn = "#057475";
export const imgCreate = "/assets/icon-action/ic-create.png";
export const colortCreBtn = "#281C9D";
export const colorBorCreBtn = "#281C9D";
export const hoverCreBtn = "#1f1399";
export const imgImport = "/assets/icons/ic-export.png";
export const colortImtBtn = "#00A8A9";
export const colorBorImtBtn = "#00A8A9";
export const hoverBorBtn = "#057475";
