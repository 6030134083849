import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
const ButtonEdit = ({ path, id, customPattern }) => {
    return (
        <Link to={customPattern ? customPattern : '/' + path + `?id=${id}`}>
            <svg className='mx-3' width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.790874 17.59C0.580874 17.59 0.380816 17.51 0.230816 17.36C0.0708157 17.2 -0.00916477 16.98 0.000835231 16.75L0.240825 12.73C0.250825 12.54 0.330806 12.35 0.470806 12.22L12.4609 0.22998C12.6109 0.0799805 12.8109 0 13.0209 0C13.2309 0 13.4309 0.0799805 13.5809 0.22998L17.3608 4.01001C17.5108 4.16001 17.5909 4.36001 17.5909 4.57001C17.5909 4.78001 17.5108 4.98 17.3608 5.13L5.37083 17.12C5.23083 17.26 5.05082 17.34 4.86082 17.35L0.840923 17.59C0.840923 17.59 0.810874 17.59 0.790874 17.59ZM1.80088 13.12L1.63084 15.95L4.46092 15.78L15.6709 4.56L13.0108 1.90002L1.80088 13.11V13.12Z" fill="#437DEE" />
                <path d="M14.6608 7.11995C14.4608 7.11995 14.2508 7.03997 14.1008 6.88997L10.7009 3.48995C10.3909 3.17995 10.3909 2.67995 10.7009 2.36995C11.0109 2.05995 11.5109 2.05995 11.8209 2.36995L15.2208 5.76998C15.5308 6.07998 15.5308 6.57997 15.2208 6.88997C15.0708 7.03997 14.8608 7.11995 14.6608 7.11995Z" fill="#437DEE" />
                <path d="M3.54085 14.84C3.34085 14.84 3.13079 14.7599 2.98079 14.6099C2.67079 14.2999 2.67079 13.7999 2.98079 13.4899L12.3409 4.12996C12.6509 3.81996 13.1509 3.81996 13.4609 4.12996C13.7709 4.43996 13.7709 4.93996 13.4609 5.24996L4.10079 14.6099C3.95079 14.7599 3.74085 14.84 3.54085 14.84Z" fill="#437DEE" />
                <path d="M1.24084 14.8301C1.19084 14.8301 1.13093 14.8301 1.08093 14.8301C1.03093 14.8301 0.980908 14.85 0.930908 14.86L0.810913 16.8101L2.68091 16.7001C2.70091 16.6301 2.72083 16.56 2.72083 16.48C2.81083 15.53 2.15084 14.8201 1.23083 14.8201L1.24084 14.8301Z" fill="#437DEE" />
                <path d="M17.5792 17.4706H8.73914C8.29914 17.4706 7.94922 17.1206 7.94922 16.6806C7.94922 16.2406 8.29914 15.8906 8.73914 15.8906H17.5792C18.0192 15.8906 18.3691 16.2406 18.3691 16.6806C18.3691 17.1206 18.0192 17.4706 17.5792 17.4706Z" fill="#437DEE" />
            </svg>
        </Link>
    )
}
ButtonEdit.propTypes = {
    path: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default ButtonEdit
