import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import Thumbnail from "./Thumbnail";
import "./UploadIMG.css";
import "../swalCustom/customStyle.css";

const dropzoneRef = createRef();

function UploadIMG(props) {
  console.log("props data : ", props);
  const Swal = require("sweetalert2");
  const imgCount = useRef(0);
  const [images, setImages] = useState([]);
  const [statusImg, setStatusImages] = useState(true);
  const [isImageFull, setIsImageFull] = useState(false);
  const [fileNameImg, setFileNameImg] = useState(null);
  let imgBoxs = useRef([]);
  let isboxInit = useRef(false);
  if (!isboxInit.current) {
    for (let i = 0; i < props.limit; i++) {
      imgBoxs.current.push("box");
      isboxInit.current = true;
    }
  }
  const [errorMessage, setErrorMessage] = useState("");
  let imgindex = 0;
  const onDrop = useCallback((acceptedFiles) => {
    handleFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const removeFile = (id) => {
    const removeimageIndex = images.findIndex((e) => e.id === id);
    if (removeimageIndex !== -1) {
      imgCount.current--;
      images.splice(removeimageIndex, 1);
      // update unsupportedFiles array
      setImages([...images]);

      imgBoxs.current.push("box");
      setIsImageFull(false);
      props.removeImg(false);
      setFileNameImg(null);
    }
  };

  const thumbs = images.map((file, index) => (
    <Thumbnail
      key={index}
      file={file}
      removeFile={removeFile}
      satusClear={props.clearData}
    />
  ));

  const handleFiles = (files) => {
    if (!props.limit) {
      props.limit = 1;
    }

    const filesArray = [...images];
    let errormsg = "";
    let slots = 0;
    slots = props.limit - imgCount.current;
    if (files.length <= slots) {
      slots = files.length;
    }

    if (slots + imgCount.current >= props.limit) {
      setIsImageFull(true);
    } else {
      setIsImageFull(false);
    }

    for (let i = 0; i < slots; i++) {
      if (validateFile(files[i]) && files[i].size <= 1024 * 1024 * 3) {
        imgCount.current++;

        filesArray.push(files[i]);
        imgBoxs.current.pop();
        setFileNameImg(files[i].name);
      } else {
        // add a new property called invalid
        setIsImageFull(false);
        files[i]["invalid"] = true;
        if (files[i].size > 1024 * 1024 * 3) {
          errormsg += files[i].name + " ขนาดภาพไม่เกิน 3MB ";
          setErrorMessage(files[i].name + " ขนาดภาพไม่เกิน 3MB");
        } else {
          errormsg += "รองรับไฟล์ PNG ขนาดภาพไม่เกิน 3MB ";
          setErrorMessage("รองรับไฟล์ PNG ขนาดภาพไม่เกิน 3MB");
        }
      }
      if (errormsg !== "") {
        Swal.fire({
          title: errormsg,
          icon: "error",
          confirmButtonText: "ยืนยัน",
        });
      }
    }
    filesArray.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          {
            id: imgindex++,
            src: e.target.result,
            file: file,
          },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
    return filesArray.length;
  };

  const validateFile = (file) => {
    // const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    const validTypes = ["image/png"];
    return validTypes.indexOf(file.type) === -1 ? false : true;
  };
  const removeFileImg = (data, index) => {
    setStatusImages(false);
    props.removeImg(false);
  };
  const openDialog = () => {
    props.removeImg(true);
    // props.removeImg(true);
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  console.log("props?.images : ", props.images);
  console.log("images? : ", images);
  useEffect(() => {
    if (images?.length < 1 && props.statusRemoveImg === true) {
      setFileNameImg(null); // Reset filename when no images
      console.log("No images selected");
    } else {
      console.log("images selected : ", images);
      props.onSelectedImages(images);
      return () => {
        images?.forEach((file) => URL.revokeObjectURL(file.src));
      };
    }
  }, [images]); // Watch the `data.image` array
  // useEffect(() => {
  //   if (images.length < 1) {
  //     setFileNameImg(null);
  //     //   const removeimageIndex = images.findIndex((e) => e.id < 1000);
  //     //   if (removeimageIndex !== -1) {
  //     //     imgCount.current--;
  //     //     images.splice(removeimageIndex, 1);
  //     //     setImages([...images]);
  //     //     imgBoxs.current.push("box");
  //     //     setIsImageFull(false);
  //     //     setStClear(true);
  //     //     props.onSelectedClear(true);
  //     //   }
  //   } else {
  //     console.log("images select 123 : ", images);
  //     // props.onSelectedImages(images);
  //     // return () => images.forEach((file) => URL.revokeObjectURL(file.src));
  //   }
  //   // props.onSelectedImages(images);
  //   // return () => images.forEach((file) => URL.revokeObjectURL(file.src));
  // }, [images, props.onSelectedImages]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Update windowWidth when the window is resized
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add an event listener to listen for window resize events
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <section className="upload fontReg font-size03">
      {props.images.length > 0 && props.statusRemoveImg === false ? (
        <>
          <>
            {" "}
            <div className="row w-100">
              <div
                className={`col-12 col-md-10 ${
                  // isImageFull ? "d-none" : "d-flex"
                  "d-flex"
                } `}
              >
                <div {...getRootProps({ className: `'dropzone' card-upload` })}>
                  <input {...getInputProps()} />
                  {fileNameImg ? (
                    <p className="mb-0">{fileNameImg}</p>
                  ) : (
                    <p className="fs-13 mb-0 align-self-center mr-2 ml-1">
                      ลากและวางไฟล์ที่นี่หรือ
                    </p>
                  )}
                  <button type="button" className="btn-uploadimg mr-2" disabled>
                    เรียกดูไฟล์
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-4 mt-md-0">
                <aside className="thumbsContainer">
                  {props.images.map((data, index) => {
                    return (
                      <div className="boxthumb" key={index}>
                        <button
                          className="remove-img text-white"
                          onClick={() => removeFileImg(data, index)}
                        >
                          x
                        </button>
                        <span className="countimg"></span>
                        <img
                          src={data ? data.src : ""}
                          className="img img-w"
                          alt="Img"
                        />
                      </div>
                    );
                  })}
                </aside>
              </div>
            </div>{" "}
            <span
              className={
                images.length > 0
                  ? "d-none"
                  : "remark-dt fontReg fs-10  text-warning"
              }
            >
              รองรับไฟล์ PNG ความละเอียด: 640x640px, <br />
              ขนาดภาพต้องไม่เกิน 3MB
            </span>
          </>
        </>
      ) : (
        <>
          {" "}
          <div className="row">
            {" "}
            <div className="col-auto">
              <div {...getRootProps({ className: `'dropzone' card-upload` })}>
                <input {...getInputProps()} />

                <p className="fs-13 mb-0 align-self-center mr-2 ml-1">
                  {fileNameImg ? fileNameImg : "ลากและวางไฟล์ที่นี่หรือ"}
                </p>

                <button
                  disabled={images.length > 0}
                  type="button"
                  className="btn-uploadimg mr-2"
                  onClick={openDialog}
                >
                  เรียกดูไฟล์
                </button>
              </div>
            </div>
            <div className="col-auto">
              <aside className="thumbsContainer">
                {thumbs}
                {imgBoxs.current.map((count, index) => {
                  return (
                    <div className={"boxthumb"} key={index}>
                      <span className="countimg">
                        {index + 1 + "/" + props.limit}
                      </span>
                    </div>
                  );
                })}
              </aside>
            </div>{" "}
          </div>{" "}
          <span className={"remark-dt fs-10  text-warning"}>
            รองรับไฟล์ PNG ความละเอียด: 640x640px, <br />
            ขนาดภาพต้องไม่เกิน 3MB
          </span>
        </>
      )}
    </section>
  );
}

export default UploadIMG;
