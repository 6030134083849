import { useState, createContext, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { originalMenus, workspaceMenus } from "./permissionMenu";
// import { applyMenuFiltering } from "./utils/mappingMenu"
import PropTypes from "prop-types";
export const listData = createContext();
export const ListContextProvider = ({ children }) => {
  const { role, permission } = useContext(GlobalContext);
  const [listMenu, setListMenu] = useState(originalMenus);

  useEffect(() => {
    if (role !== "superadmin") {
      // ####################### รอเปิดใช้งานเมื่อพัฒนาระบบเสร็จแล้ว นี่คือ permission menu ####################################### 
      const original = [...workspaceMenus(localStorage.getItem('wuuid'))]
      // const appliedMenus = applyMenuFiltering(original, permission)
      setListMenu(original)
    } else {
      // ####################### รอเปิดใช้งานเมื่อพัฒนาระบบเสร็จแล้ว นี่คือ permission menu ####################################### 
      const original = [...originalMenus]
      // const appliedMenus = applyMenuFiltering(original, permission)
      // setListMenu(appliedMenus)      
      setListMenu(original)
    }
  }, [role, permission]);

  return (
    <listData.Provider value={{ listMenu, setListMenu }}>
      {children}
    </listData.Provider>
  );
};
ListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
