import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import translationTH from './locales/th/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    th: {
        translation: translationTH
    }
};

i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // pass i18n instance to react-i18next.
    .init({
        resources,
        lng: 'th', // default language
        fallbackLng: 'th', // fallback language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
