import { getInstance } from "./baseapi"

const instace = getInstance({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const fetchData = async (search) => {
    const response = await instace.get('/parking/reserve/admin', {
        headers: {
            Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
        },
        params: {
            is_transaction: true,
            all: true,
            ...search // Add query string parameters here
        }
    });
    return response
};