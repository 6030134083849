import Joi from 'joi';

export const schema = Joi.object({
    password: Joi.string()
        .min(8) // Minimum 8 characters
        .required()
        .messages({
            'string.min': 'Password must be at least 8 characters long.',
            'string.empty': 'Password is required.',
        }),

    newpassword: Joi.string()
        .valid(Joi.ref('password')) // Must match the password field
        .required()
        .messages({
            'any.only': 'Passwords do not match.',
            'string.empty': 'Confirm password is required.',
        })
});
