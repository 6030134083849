import { useContext, useEffect } from 'react'
import { PopupContext } from "src/components/context/PopupContext"

export const usePopup = ({ initialState }) => {
    const { popup, setPopup } = useContext(PopupContext)
    useEffect(() => {
        setPopup(initialState || {})
    }, [])
    return {
        popup, setPopup
    }
}
