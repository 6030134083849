import React from "react";
import "./Thumbnail.css";
import iconDelete from "../../assets/icons/ic-add.png";
function Thumbnail(props) {
  const { file, removeFile } = props;

  return (
    <div className="thumb">
      <div className="thumbInner">
        <img src={file.src} className="img ml-3 img-w" alt="" />
      </div>
      <button className={"remove-img"} onClick={() => removeFile(file.id)}>
        <img
          src={iconDelete}
          alt="ExportFiles Icon"
          className="ic-remove-img"
        />
      </button>
    </div>
  );
}

export default Thumbnail;
