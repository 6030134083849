import React, { useEffect, useState } from "react";
import Header from "../../template/Header";
import Menu from "../../template/Menu";
import Footer from "../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import TableContent from "../../components/TableContent/TableContent.jsx";
import Loading from "../../components/loading/Loading";
import HeadTitle from "../../template/HeadTitle";
import { DataDropdownStatus } from "../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch.jsx";
import InputData from "../../components/Input/index";
import { Card } from "react-bootstrap";
import {
  colorSearchBtn,
  colorResetBtn,
  iconSearchBtn,
  iconResetBtn,
  hoverSearchBtn,
  hoverResetBtn,
} from "../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../components/useDynamic/Button_DynamicStyle";
import { fetchData } from "../../api/fetchDataParking.js";
import ButtonEdit from "../../components/button/edit/Edit.jsx";
const buttonConfig = {
  colorSearchBtn: colorSearchBtn,
  colorResetBtn: colorResetBtn,
  iconSearchBtn: iconSearchBtn,
  iconResetBtn: iconResetBtn,
  hoverSearchBtn: hoverSearchBtn,
  hoverResetBtn: hoverResetBtn,
};
function MasterData() {
  useButtonDynamicStyles(buttonConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    name_th: null,
    name_en: null,
    created_at: null,
    updated_at: null,
  });

  const [raw, setRaw] = useState([]);
  const initState = {
    data_per_page: 5,
  };
  const [search, setSearch] = useState(initState);
  const dataHeader = [
    {
      Header: "Appeal Type ID",
      accessor: "appeal_id",
      sorted: true,
    },
    {
      Header: "Appeal Type Name (EN)",
      accessor: "appealname_en",
    },
    {
      Header: "Appeal Type Name (TH)",
      accessor: "appealname_th",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  useEffect(() => {
    if (!localStorage.getItem("tokenBackoffice")) {
      navigate("/login");
    }
    getTableData();
  }, [search.page_number, search.data_per_page]);
  async function getTableData() {
    try {
      const response = await fetchData(search);
      // เตรียมข้อมูลเพื่อแสดงในตาราง
      setRaw({
        ...response.data,
        data: response?.data.data.map((item, index) => {
          const no =
            (response.data.page_number - 1) * response.data.data_per_page +
            (index + 1);
          return {
            appeal_id: item.first_name,
            appealname_en: item.last_name,
            appealname_th: item.member_type,
            status: item.status,
            action: (
              <ButtonEdit path={"AddEditMasterAppealType"} id={`${item.id}`} />
            ),
          };
        }),
      });
    } catch (error) {}
  }
  function resetDataSearch() {
    setSearchData({
      ...searchData,
      name_th: "",
      name_en: "",
      created_at: null,
      updated_at: null,
    });

    getTableData();
  }

  return (
    <>
      {isLoading ? <Loading /> : ""}

      <Header parentmenu={"Master Data"} />
      <Menu parentmenu={"MasterData"} activemenu={"AppealType"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="ตารางข้อมูลประเภทการร้องเรียน"
            status={true}
            path={"AddEditMasterAppealType"}
            pathImport={"ImportDataAppealType"}
            pathExport={""}
          />

          <div className="box-white mt-5">
            <Card>
              <Card.Body>
                {" "}
                <Row className="">
                  <Col xs={6} md={4}>
                  <p className="fs-15 font-weight-bold mb-1">Search</p>
                    <InputData
                      type="text"
                      className="inp-data"
                      id="account_name"
                      placeholder="Search"
                      value={searchData.name_th}
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          name_th: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs={6} md={4}>
                  <p className="fs-15 font-weight-bold mb-1">Status</p>
                    <DropdownSearch
                      placeholder={"Status"}
                      value={999}
                      onChange={(e) => {
                        setSearch({
                          ...search,
                          search: e.value,
                        });
                      }}
                      options={DataDropdownStatus}
                    />
                  </Col>{" "}
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button
                      className="btn-search"
                      onClick={() => getTableData()}
                    >
                      {/* <i className="fas fa-search"></i>  */}
                      Search
                    </button>
                  </Col>
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button
                      className="btn-reset"
                      onClick={() => resetDataSearch()}
                    >
                      {/* <i className="fas fa-undo"></i>  */}
                      Clear
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <TableContent
              columns={dataHeader}
              data={raw.data || []}
              total={raw.total || 0}
              currentPage={raw.page_number || 0}
              dataPerPage={raw.data_per_page}
              handlerPerpage={(dataPerPage) =>
                setSearch({
                  ...search,
                  data_per_page: dataPerPage,
                })
              }
              handlerSort={(eSort) => {
                console.log(eSort);
                setSearch({
                  ...search,
                  [eSort.key]: eSort.direction,
                });
              }}
              handlerChangePage={(pageNumber) => {
                setSearch({
                  ...search,
                  page_number: pageNumber,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MasterData;
