import React from "react";
import {
  footerBgColor,
  borderFooterColor,
  titleFooter,
  linkFooter,
  textCompany,
  textDescrip,
  heightFooter,
} from "../components/ThemeConfig/footerConfig";
import { useFooterDynamicStyles } from "../components/useDynamic/footer_DynamicStyle";
import { textPrimary, textSecond } from "../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../components/useDynamic/text_DynamicStyle";
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
const footerConfig = {
  footerBgColor: footerBgColor,
  borderFooterColor: borderFooterColor,
  titleFooter: titleFooter,
  linkFooter: linkFooter,
  textCompany: textCompany,
  textDescrip: textDescrip,
  heightFooter: heightFooter,
};
function Footer() {
  useFooterDynamicStyles(footerConfig);
  useTextDynamicStyles(textConfig);
  return (
    <footer className="main-footer text-main">
      <strong>
        {titleFooter}&nbsp;
        <a href={linkFooter} className="text-main">
          &nbsp;{textCompany}
        </a>
        .
      </strong>
      &nbsp;{textDescrip}
      <div className="float-right d-none d-sm-inline-block">Version 1.0.1</div>
    </footer>
  );
}

export default Footer;
