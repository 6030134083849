import React, { useState, useEffect } from "react";
import ChartBar from "../charts/ChartBar";
import { Link } from "react-router-dom";
import "./cardDashboard.css";
import { Chart } from "chart.js";
Chart.defaults.font.family = "'db_heaventregular'";

function CardPromotionalUse(props) {
  const [UseData, setUserData] = useState({});
  useEffect(() => {}, []);
  return (
    <div className="">
      <p>
        <span className="border-left-style px-1 font-weight-bold">
          กราฟแสดงปริมาณผู้เข้าชมเว็บไซต์ ประจำปี
          {/* {props.dataYear} */}
        </span>{" "}
      </p>
      <div className="row m-0  ">
        <div className="col-12 col-md-12 mt-1">
          {/* <ChartBar
            chartPromotionData={{
              labels: props.dataUser.map((data) => data.month),
              datasets: [
                {
                  data: props.dataUser.map((data) => data.quantity),
                  label: "จำนวนคน ",
                  backgroundColor: "#BFF2DC",
                  hoverBackgroundColor: "#55C091",
                  borderRadius: 10,
                  borderSkipped: false,
                },
              ],
            }}
          /> */}
          <p className="fontReg font-size03 font-weight-light colorGray01 d-flex justify-content-center text-center mt-2">
            อัปเดตล่าสุด 
            {/* {props.dataTime} */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardPromotionalUse;
