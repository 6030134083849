import React, { useEffect, useState } from "react";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import { DataDropdownBrand } from "../../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../../components/DropdownSearch/DropdownSearch.jsx";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import {
  textPrimary,
  textSecond,
} from "../../../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../../../components/useDynamic/text_DynamicStyle";
import Permission from "./FeaturePermission/Permission";
import { getLists as getMenus, create, getTransactionById, update } from "src/api/subscriptionRoute"
import { getLists as getBrands } from "src/api/brandRoutes"
import { getLists as getProjects } from "src/api/projectRoutes"
import { orderBy } from "src/utils/orderBy"
import { useParams } from 'react-router-dom'
import { usePopup } from 'src/hooks/usePopup';

const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
function AddEditData() {
  const { popup, setPopup } = usePopup({
    status: 'success',
    show: false,
    message: "",
    onConfirm: null,
    onCancel: null
  })

  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  useTextDynamicStyles(textConfig);
  const navigate = useNavigate();
  const { id } = useParams()
  const [isLoading, setLoading] = useState(false);
  const [masterData, setMasterData] = useState({
    menus: [],
    brands: [],
    projects: []
  })
  const [data, setData] = useState({
    subscriptionId: id,
    brand: "",
    project: "",
    status: true,
    menus: []
  });

  const navigatePage = (page) => {
    navigate("/" + page);
  };

  useEffect(() => {
    fetchMasterData()
  }, [])

  useEffect(() => {
    if (id) {
      fetchById()
    }
  }, [id])

  async function fetchById() {
    const result = await getTransactionById(id)
    const resultMenus = await getMenus()
    const resultWithIdLists = result.data?.data?.menu_group.map((item => item.uuid))

    let menus = result.data?.data?.menu_group.map(item => {
      return {
        ...item,
        uuid: item.uuid,
        title: item.group_name,
        value: true
      }
    })

    const masterDataMenu = resultMenus?.data?.data?.filter(item => !resultWithIdLists.includes(item.uuid))?.map((item => {
      return {
        ...item,
        title: item.group_name
      }
    }))
    const mapData = [...menus, ...masterDataMenu]

    setData({
      ...data,
      brand: result.data.data.project.brand.uuid,
      project: result.data.data.project.uuid,
      menus: orderBy(mapData)
    })
  }

  const fetchMasterData = async () => {
    const resultMenus = await getMenus()
    const resultBrands = await getBrands()
    const resultProjects = await getProjects()


    const dataFromAPI = resultMenus?.data?.data?.map(item => {
      return {
        uuid: item.uuid,
        title: item.group_name,
        value: 0
      }
    })
    setMasterData({
      menus: [...dataFromAPI],
      brands: resultBrands?.data?.data?.map((item => {
        return {
          label: item.brand_th,
          value: item.uuid
        }
      })),
      projects: resultProjects?.data?.data?.map((item => {
        return {
          label: item.project_th,
          value: item.uuid
        }
      }))
    }
    )
  }


  const handleSubmit = async () => {
    const mapMenuGroup = data.menus.filter((item => item.value))
    const mapObj = {
      project_id: data.project || '',
      is_active: data.status,
      menu_group: mapMenuGroup?.map((item => item.uuid))
    }
    if (id) {
      const response = await update(id, mapObj)
      setPopup({
        ...popup,
        show: true,
        status: 'success',
        message: response?.data?.message,
        onConfirm: () => {
          setPopup({
            ...popup,
            show: false
          })
          navigate('/masterDataSubscription')
        }
      })
    } else {
      const response = await create(mapObj)
      setPopup({
        ...popup,
        show: true,
        status: 'success',
        message: response?.data?.message,
        onConfirm: () => {
          setPopup({
            ...popup,
            show: false
          })
          navigate('/masterDataSubscription')
        }
      })
    }
  }
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Header parentmenu={"Subscription"} />
      <Menu parentmenu={"Subscription"} activemenu={"Subscription"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดข้อมูลสมัครใช้บริการ"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  {
                    id &&
                    <Row>
                      <Col xs={4} md={5} xl={5} className="align-self-center">
                        <span className="fs-15">Subscription ID</span>
                      </Col>

                      <Col xs={8} md={7} xl={7}>
                        <InputData
                          type="text"
                          className="inp-data"
                          id="subscriptionId"
                          disable
                          placeholder="Subscription ID"
                          value={data.subscriptionId}
                          onChange={(event) =>
                            setData({
                              ...data,
                              subscriptionId: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  }
                  {" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Brand</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Brand"}
                        value={data.brand}
                        onChange={(e) => {
                          setData({
                            ...data,
                            brand: e.value,
                          });
                        }}
                        options={masterData.brands}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Project"}
                        value={data.project}
                        onChange={(e) => {
                          setData({
                            ...data,
                            project: e.value,
                          });
                        }}
                        options={masterData.projects}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <h5 className="text-main font-weight-bold">
                  ข้อมูลสมัครใช้บริการ
                </h5>
                <Permission
                  handleChange={(e) => {
                    const selected = e.filter((item => item.value === true)).map((item => item.title))
                    setData({
                      ...data,
                      menus: masterData.menus.map((item => {
                        return {
                          ...item,
                          uuid: item.uuid,
                          value: selected.includes(item.title)
                        }
                      }))
                    })
                  }
                  }
                  options={masterData.menus}
                  currentValue={data.menus}
                />
              </Row>
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("masterDataSubscription");
                  }}
                >
                  Back
                </button>{" "}
                <button
                  className="btn-save"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
