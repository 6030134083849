import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { useTable, useSortBy } from 'react-table';
import { Table, Pagination, Form } from 'react-bootstrap';
import DropdownSearch from '../DropdownSearch/DropdownSearch';
import "./style.css"
const MyTable = ({
    columns,
    data,
    total,
    bgHeader,
    handlerPerpage,
    dataPerPage,
    handlerChangePage,
    labelCheck,
    checkPosition,
    checkAll,
    check,
    currentPage,
    onChange,
    handlerSort
}) => {
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

    const handleSort = (columnId) => {
        setSortConfig((prev) => {
            const direction =
                prev.key === columnId && prev.direction === "ASC" ? "DESC" : "ASC";
            return { key: columnId, direction };
        });
        handlerSort(sortConfig)
    };

    const dynamicRender = (columns) => {
        const renderColumn = checkAll
            ? [
                {
                    id: 'selectAll',
                    Header: (
                        <div className='d-flex align-items-center'>
                            <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onChange={() => {
                                    setSelectAllChecked(!selectAllChecked)
                                    const editable = data?.map((item => {
                                        return {
                                            ...item,
                                            value: !selectAllChecked
                                        }
                                    }))
                                    onChange(editable)
                                }}
                            />
                            {labelCheck && <span className='ps-2'>{labelCheck}</span>}
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <input
                            type="checkbox"
                            checked={data[row.index].value}
                            onChange={() => {
                                setSelectAllChecked(false)
                                const editable = [...data]
                                editable[row.index].value = !editable[row.index].value
                                onChange(editable)
                            }}
                            disabled={!checkAll}
                        />
                    ),
                },
            ]
            : check
                ? [
                    {
                        id: 'selectRow',
                        Header: labelCheck || '',
                        Cell: ({ row }) => {
                            if (row?.original?.title?.toLowerCase() === 'all') {
                                return (
                                    <input
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={() => {
                                            setSelectAllChecked(!selectAllChecked)
                                            const editable = data?.map((item => {
                                                return {
                                                    ...item,
                                                    value: !selectAllChecked
                                                }
                                            }))
                                            onChange(editable)
                                        }}
                                        disabled={!check}
                                    />
                                )
                            }
                            return (
                                <input
                                    type="checkbox"
                                    checked={data[row.index].value}
                                    onChange={() => {
                                        setSelectAllChecked(false)
                                        const editable = [...data]
                                        editable[row.index].value = !editable[row.index].value
                                        onChange(editable)
                                    }}
                                    disabled={!check}
                                />
                            )
                        },
                    },
                ]
                : [];

        if (!checkPosition) {
            return [...renderColumn, ...columns];
        }
        if (checkPosition === 'right') {
            return [...columns, ...renderColumn];
        }
        return [...renderColumn, ...columns];
    };

    const memoizedColumns = useMemo(
        () => dynamicRender(columns),
        [columns, checkAll, check, selectAllChecked]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns: memoizedColumns,
            data,
        },
        useSortBy
    );

    const pageCount = Math.ceil(total / dataPerPage);

    const handledataPerPageChange = (event) => {
        handlerPerpage(Number(event.value));
    };

    const orderStart =
        (currentPage - 1) * dataPerPage + 1

    const customizeBgHeader = bgHeader || '#281C9D'
    return (
        <div>
            <Table bordered hover {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, colIndex) => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        backgroundColor: customizeBgHeader,
                                        color: '#ffffff',
                                        border: 'none',
                                        height: 60,
                                        verticalAlign: 'middle',
                                    }}
                                    key={colIndex}
                                >
                                    {column.render("Header")}
                                    {column.sorted && (
                                        <button
                                            className='mx-3'
                                            onClick={() => handleSort(column.id)}
                                            style={{
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                                color: '#fff',
                                            }}
                                        >
                                            {sortConfig.key === column.id ? (
                                                sortConfig.direction === "ASC" ? (
                                                    <i className="fa fa-sort-up"></i>
                                                ) : (
                                                    <i className="fa fa-sort-down"></i>
                                                )
                                            ) : (
                                                <i className="fa fa-sort"></i>
                                            )}
                                        </button>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={rIndex}>
                                {row.cells.map((cell, cellIndex) => (
                                    <td {...cell.getCellProps()} key={`${rIndex}-${cellIndex}`}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className="mt-4">
                {
                    dataPerPage && 
                    <Pagination className='d-flex align-items-center w-100 justify-content-end'>
                        <Form.Group controlId="dataPerPage" className='d-flex align-items-center'>
                            <div>จำนวนแถวในหนึ่งหน้า:</div>
                            <DropdownSearch
                                value={dataPerPage}
                                onChange={handledataPerPageChange}
                                style={{
                                    width: 130,
                                    border: '1px solid #A9A9A9',
                                    borderRadius: 15,
                                    paddingLeft: '.1rem',
                                    paddingRight: '.1rem',
                                    margin: '0 .5rem',
                                }}
                                options={[
                                    // { label: '5', value: 5 },
                                    { label: '10', value: 10 },
                                    { label: '20', value: 20 },
                                    { label: '30', value: 30 },
                                    { label: '100', value: 100 },
                                ]}
                            />
                        </Form.Group>
                        <div className='px-2'>
                            {orderStart}-{orderStart + (dataPerPage - 1)} จาก {total}
                        </div>
                        <div>
                            {
                                
                                <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 8 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => currentPage > 0 && handlerChangePage(Number(currentPage) - 1)}
                                >
                                    <path d="M6.5 1.5L1.5 6.5L6.5 11.5" stroke="#A9A9A9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>

                        <div className="d-flex align-items-center mx-2" id='currentPage'>{currentPage}</div>

                        <div>
                            {
                              
                                <svg
                                    id='nextPage'
                                    width="13"
                                    height="12"
                                    viewBox="0 0 7 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handlerChangePage(Number(currentPage) + 1)}
                                >
                                    <path d="M1 1L6 6L1 11" stroke="#A9A9A9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>
                        <div className="d-flex align-items-center mx-2">{pageCount}</div>
                    </Pagination>
                }
            </div>
        </div >
    );
};
MyTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired, // Ensure columns is an array of objects
    data: PropTypes.arrayOf(PropTypes.object).isRequired, // Ensure data is an array of objects
    total: PropTypes.number.isRequired,
    bgHeader: PropTypes.string,
    handlerPerpage: PropTypes.func.isRequired,
    dataPerPage: PropTypes.number.isRequired,
    handlerChangePage: PropTypes.func.isRequired,
    labelCheck: PropTypes.string,
    checkPosition: PropTypes.oneOf(['left', 'right']),
    checkAll: PropTypes.bool,
    check: PropTypes.bool,
    currentPage: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    handlerSort: PropTypes.func.isRequired
};

// Default props (optional)
MyTable.defaultProps = {
    bgHeader: '#281C9D',
    labelCheck: '',
    checkPosition: 'left',
    checkAll: false,
    check: false,
    onChange: null
};
export default MyTable;