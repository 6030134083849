import axios from "axios";

export const getInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        localStorage.removeItem(`tokenBackoffice`);
        sessionStorage.removeItem(`tokenBackoffice`);
        window.location.href = "/backoffice/login";
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


// Helper function to set headers
const getHeaders = (data) => {
  const token = localStorage.getItem('tokenBackoffice')
  const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['language'] = `th`;
  }
  return headers;
};

// POST Function
export const POST = async (url, data) => {
  try {
    const headers = getHeaders(data);
    const response = await instance.post(url, data, { headers });    
    return response?.data
  } catch (error) {
    console.log(error);
    
    return error.response || null;
  }
};

// GET Function
export const GET = async (url, query) => {
  try {
    const headers = getHeaders(); // No data needed for GET
    const response = await instance.get(url, {
      params: query,
      headers
    });

    return response.status === 200 ? response : null;
  } catch (error) {
    return error.response ? error.response.status : null;
  }
};

// PUT Function
export const PUT = async (url, data, token) => {
  try {
    const headers = getHeaders(data);
    const response = await instance.put(url, data, { headers });

    return response.status === 200 ? response : null;
  } catch (error) {
    return error.response ? error.response.status : null;
  }
};

// DELETE Function
export const DELETE = async (url) => {
  try {
    const headers = getHeaders(); // No data needed for DELETE
    const response = await instance.delete(url, { headers });

    return response.status === 200 ? response : null;
  } catch (error) {
    return error.response ? error.response.status : null;
  }
};