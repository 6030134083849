import React, { useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import styled from "styled-components";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
const DropzoneContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 4px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ isDragging }) =>
    isDragging &&
    `
    background-color: rgba(0, 0, 0, 0.1);
  `}
`;

const DropzoneMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
`;

const FileName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin-top: 8px;
`;

const SelectFileButton = styled.button`
  top: 0;
  left: 0;
  width: 20%;
  height: 50%;
  margin-right: 5%;
  opacity: 1;
  cursor: pointer;
`;

const ImportExcel = ({ onImport }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      const mimeType = files[0].type;
      if (
        mimeType !== "application/vnd.ms-excel" &&
        mimeType !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        Swal.fire({
          title: "กรุณาตรวจสอบข้อมูล ไฟล์ต้องเป็น excel เท่านั้น",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
        return;
      } else {
        setFileName(files[0].name);
        ExcelRenderer(files[0], async (err, res) => {
          if (err) {
            console.log(err);
          } else {
            await onImport(res.rows);
          }
        });
      }
    }
  };

  const handleClick = (e) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx,.xls";
    input.onchange = (e) => {
      const file = e.target.files[0];
      const mimeType = file.type;
      if (
        mimeType !== "application/vnd.ms-excel" &&
        mimeType !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        Swal.fire({
          title: "กรุณาตรวจสอบข้อมูล ไฟล์ต้องเป็น excel เท่านั้น",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: `ยกเลิก`,
          reverseButtons: true,
        }).then((result) => {});
        return;
      }
      setFileName(file.name);
      ExcelRenderer(file, async (err, res) => {
        if (err) {
          console.log(err);
        } else {
          await onImport(res.rows);
        }
      });
    };
    input.click();
  };

  return (
    <DropzoneContainer
      className="py-3"
      isDragging={isDragging}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      {fileName ? (
        <>
          <DropzoneMessage>{fileName}</DropzoneMessage>
          <FileName>{isDragging ? "ลากและวางไฟล์ที่นี่หรือ" : ""}</FileName>
        </>
      ) : (
        <DropzoneMessage>ลากและวางไฟล์ที่นี่หรือ</DropzoneMessage>
      )}
      <SelectFileButton className="btn-file ml-2 py-3">
        เรียกดูไฟล์
      </SelectFileButton>
    </DropzoneContainer>
  );
};
ImportExcel.propTypes = {
  onImport: PropTypes.func.isRequired, // Use PropTypes.func if this prop is a function
};
export default ImportExcel;
