import Joi from "joi";

export const schema = Joi.object({
  username: Joi.string()
    // .min(3)
    .pattern(/^\S+$/) // Disallow whitespace
    .required()
    .messages({
      "string.empty": "จำเป็นต้องกรอกข้อมูล",
      // "string.min": "จำเป็นต้องกรอกข้อมูล ที่ email",
    }),

  password: Joi.string()
    .min(8) // Minimum 8 characters
    .max(20) // Maximum 20 characters
    .pattern(new RegExp("^[0-9a-zA-Z@$!%*?&]+$")) //nosonar
    .required()
    .messages({
      "string.empty": "จำเป็นต้องกรอกข้อมูล",
      "string.min": "Password must be at least 8 characters long",
      "string.max": "Password must not exceed 20 characters",
    }),
});
