import React from 'react'
import { useParams } from 'react-router-dom'
import Header from "../../template/Header";
import Menu from "../../template/Menu";
import HeadTitle from "../../template/HeadTitle";
import "./style.css"
export default function ExampleWorkspace() {
    const { uuid1, uuid2, uuid3 } = useParams()
    return (
        <div>
            <Header parentmenu={"การจัดการระบบโครงการ"} />
            <Menu parentmenu={"adminManage"} activemenu={"Dashboard"} />
            {/* <h1>
                ระบบบริการจัดการโครงการ
            </h1> */}
            <div className="content-wrapper">
                <div className="container-fluid ">
                    <HeadTitle
                        title="การจัดการระบบโครงการ"
                        subTitle="Dashboard / Dashboard หลัก"
                        img={"assets/icons/ic-dashboard.png"}
                        pathLink={""}
                    />
                    <div className="box-white mt-5">
                        <h3>
                            รหัสโครงการของคุณคือ
                        </h3>
                        {uuid1}{uuid2}{uuid3}
                    </div>
                </div>
            </div>
        </div>
    )
}
