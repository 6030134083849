import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const DropdownSearch = ({
    options,
    onChange,
    placeholder,
    value,
    style
}) => {
    let customStyles = {};
    if (style) {
        customStyles = {
            control: (provided) => ({
                ...provided,
                ...style,
            }),
        };
    }

    return (
        <div>
            <Select 
                options={options}
                styles={customStyles}
                placeholder={placeholder || ''}
                defaultValue={value && options.filter(item => item.value === value) || null}
                value={value && options.filter(item => item.value === value) || null}
                onChange={onChange}
            />
        </div>
    );
};

// Adding PropTypes validation
DropdownSearch.propTypes = {
    options: PropTypes.array.isRequired, // Ensure options is an array and required
    onChange: PropTypes.func.isRequired, // Ensure onChange is a function and required
    placeholder: PropTypes.string, // placeholder is optional, so it's just a string
    value: PropTypes.any, // value can be any type, since it depends on the options
    style: PropTypes.object // style should be an object
};

export default DropdownSearch;
