import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./i18n";
import "./App.css";
import "./components/cssDynamic/MenuTab.css";
import "./components/cssDynamic/Header.css";
import "./components/cssDynamic/Footer.css";
import "./components/cssDynamic/tableData.css";
import "./components/cssDynamic/buttons.css";
import "./components/cssDynamic/checkbox.css";
import "./components/cssDynamic/gridCard.css";
import "./components/cssDynamic/fontsData.css";
import "./components/cssDynamic/permission.css";
import "./components/cssDynamic/texts.css";
import "./components/cssDynamic/process.css";
import "./components/progressbar/progress.css";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import ViewDashboard from "./pages/DashboardData";
import MasterDataBrand from "./pages/MasterData/masterDataBrand";
import AddEditBrand from "./pages/MasterData/AddEditData/AddEditBrand";
import ImportBrand from "./pages/MasterData/ImportData/ImportBrand";
import MasterDataProject from "./pages/MasterData/masterDataProject";
import AddEditProject from "./pages/MasterData/AddEditData/AddEditProject";
import ImportProject from "./pages/MasterData/ImportData/ImportProject";
import MasterDataResidentType from "./pages/MasterData/masterDataResidentType";
import AddEditResidentType from "./pages/MasterData/AddEditData/AddEditResidentType";
import ImportResidentType from "./pages/MasterData/ImportData/ImportResidentType";
import MasterDataRepairType from "./pages/MasterData/masterDataRepairType";
import AddEditRepairType from "./pages/MasterData/AddEditData/AddEditRepairType";
import ImportRepairType from "./pages/MasterData/ImportData/ImportRepairType";
import MasterDataAppealType from "./pages/MasterData/masterDataAppealType";
import AddEditAppealType from "./pages/MasterData/AddEditData/AddEditAppealType";
import ImportAppealType from "./pages/MasterData/ImportData/ImportAppealType";
import MasterDataSubscription from "./pages/Subscription/masterDataSubscription";
import AddEditSubscription from "./pages/Subscription/AddEditData/AddEditSubscription";
import MasterDataAdmin from "./pages/AdminManagement/masterDataAdmin";
import AddEditAdmin from "./pages/AdminManagement/AddEditData/AddEditAdmin";
import ImportAdmin from "./pages/AdminManagement/ImportData/ImportAdmin";


import MasterDataResidence from "./pages/MasterData/masterDataResidence";
import AddEditResidence from "./pages/MasterData/AddEditData/AddEditResidence";
import ImportResidence from "./pages/MasterData/ImportData/ImportResidence";
import { ListContextProvider } from "./components/context/dataMenu";
import Workspace from "./pages/Workspace/Workspace";
import MasterDataUnitManagement from "./pages/UnitManagement/masterDataUnitManagement";
import AddEditUnitManagement from "./pages/UnitManagement/AddEditData/AddEditUnitManagement";
import ImportUnitManagement from "./pages/UnitManagement/ImportData/ImportUnitManagement";
import MasterDataResident from "./pages/Resident/masterDataResident";
import AddEditResident from "./pages/Resident/AddEditData/AddEditResident";
import GlobalProvider, { GlobalContext } from "./contexts/GlobalContext";
import PopupContextProvider from "./components/context/PopupContext";

function App() {
  const RenderWithFetchOnRouteChange = ({ children }) => { //nosonar
    const location = useLocation(); // Hook to get current route

    const { fetchUserInfo } = useContext(GlobalContext);
    useEffect(() => {
      const pathParts = location.pathname.split("/")?.filter((item) => item);

      fetchUserInfo(pathParts);
    }, [location]);

    return <>{children}</>;
  };
  return (
    <GlobalProvider>
      <div className="wrapper">
        <BrowserRouter basename={"/backoffice"}>
          <RenderWithFetchOnRouteChange>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/login" replace={true} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword" element={
                <PopupContextProvider>
                  <ForgotPassword />
                </PopupContextProvider>
              } />
              <Route path="/reset-password/:token" element={
                <PopupContextProvider>
                  <ChangePassword />
                </PopupContextProvider>
              } />{" "}
              {/* --masterData  -- */}
              <Route
                path="/masterDataResidence"
                element={
                  <ListContextProvider>
                    <MasterDataResidence />
                  </ListContextProvider>
                }
              />
              <Route
                path="/masterDataBrand"
                element={
                  <ListContextProvider>
                    <MasterDataBrand />
                  </ListContextProvider>
                }
              />
              <Route
                path="/masterDataProject"
                element={
                  <ListContextProvider>
                    <PopupContextProvider>
                      <MasterDataProject />
                    </PopupContextProvider>
                  </ListContextProvider>
                }
              />
              <Route
                path="/masterDataResidentType"
                element={
                  <ListContextProvider>
                    <MasterDataResidentType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/masterDataRepairType"
                element={
                  <ListContextProvider>
                    <MasterDataRepairType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/masterDataAppealType"
                element={
                  <ListContextProvider>
                    <MasterDataAppealType />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/masterDataSubscription"
                element={
                  <ListContextProvider>
                    <PopupContextProvider>
                      <MasterDataSubscription />
                    </PopupContextProvider>
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/masterDataAdmin"
                element={
                  <ListContextProvider>
                    <MasterDataAdmin />
                  </ListContextProvider>
                }
              />
              {/* --AddEdit-- */}
              <Route
                path="/AddEditMasterResidence"
                element={
                  <ListContextProvider>
                    <AddEditResidence />
                  </ListContextProvider>
                }
              />
              <Route
                path="/AddEditMasterBrand"
                element={
                  <ListContextProvider>
                    <AddEditBrand />
                  </ListContextProvider>
                }
              />
              <Route
                path="/AddEditMasterProject"
                element={
                  <ListContextProvider>
                    <PopupContextProvider>
                      <AddEditProject />
                    </PopupContextProvider>
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/AddEditMasterResidentType"
                element={
                  <ListContextProvider>
                    <AddEditResidentType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/AddEditMasterRepairType"
                element={
                  <ListContextProvider>
                    <AddEditRepairType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/AddEditMasterAppealType"
                element={
                  <ListContextProvider>
                    <AddEditAppealType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/Subscription/:id"
                element={
                  <ListContextProvider>
                    <PopupContextProvider>
                      <AddEditSubscription />
                    </PopupContextProvider>
                  </ListContextProvider>
                }
              />
              <Route
                path="/Subscription"
                element={
                  <ListContextProvider>
                    <PopupContextProvider>
                      <AddEditSubscription />
                    </PopupContextProvider>
                  </ListContextProvider>
                }
              />
              <Route
                path="/AddEditAdmin"
                element={
                  <ListContextProvider>
                    <AddEditAdmin />
                  </ListContextProvider>
                }
              />
              {/* --Import-- */}
              <Route
                path="/ImportDataResidence"
                element={
                  <ListContextProvider>
                    <ImportResidence />
                  </ListContextProvider>
                }
              />
              <Route
                path="/ImportDataBrand"
                element={
                  <ListContextProvider>
                    <ImportBrand />
                  </ListContextProvider>
                }
              />
              <Route
                path="/ImportDataProject"
                element={
                  <ListContextProvider>
                    <ImportProject />
                  </ListContextProvider>
                }
              />
              <Route
                path="/ImportDataResidentType"
                element={
                  <ListContextProvider>
                    <ImportResidentType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/ImportDataRepairType"
                element={
                  <ListContextProvider>
                    <ImportRepairType />
                  </ListContextProvider>
                }
              />
              <Route
                path="/ImportDataAppealType"
                element={
                  <ListContextProvider>
                    <ImportAppealType />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/ImportDataAdmin"
                element={
                  <ListContextProvider>
                    <ImportAdmin />
                  </ListContextProvider>
                }
              />
              {/* ---- */}

              <Route
                path="/infoDashboard"
                element={
                  <ListContextProvider>
                    <ViewDashboard />
                  </ListContextProvider>
                }
              />

              {/* ------------------ workspace -----------------------------*/}
              <Route
                path="/workspace/:uuid1"
                element={
                  <ListContextProvider>
                    <Workspace />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/workspace/:uuid1/master_data"
                element={<ListContextProvider></ListContextProvider>}
              />{" "}
              <Route
                path="/workspace/:uuid1/masterDataUnitManagement"
                element={
                  <ListContextProvider>
                    <MasterDataUnitManagement />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/workspace/:uuid1/AddEditUnitManagement"
                element={
                  <ListContextProvider>
                    <AddEditUnitManagement />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/workspace/:uuid1/ImportDataUnitManagement"
                element={
                  <ListContextProvider>
                    <ImportUnitManagement />
                  </ListContextProvider>
                }
              />{" "}
              <Route
                path="/workspace/:uuid1/masterDataResident"
                element={
                  <ListContextProvider>
                    <MasterDataResident />
                  </ListContextProvider>
                }
              />
              <Route
                path="/workspace/:uuid1/AddEditResident"
                element={
                  <ListContextProvider>
                    <AddEditResident />
                  </ListContextProvider>
                }
              />{" "}
              {/* ------------------ workspace -----------------------------*/}
            </Routes>
          </RenderWithFetchOnRouteChange>
        </BrowserRouter>
      </div>
    </GlobalProvider>
  );
}

export default App;
