import React, { useState, useEffect } from "react";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import Loading from "../../../components/loading/Loading";
import { verifyDataImport, postImportData } from "../../../api/import_api";
import HeadTitle from "../../../template/HeadTitle";
import { Link, useNavigate } from "react-router-dom";
import ImportExcel from "../../../components/uploads/ExcelRender";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
  colorVerifyBtn,
  hoverVerifyBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import {
  colorPrimary,
  colorPrimaryhover,
} from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import TableContent from "../../../components/TableContent/TableContent.jsx";
import { fetchData } from "../../../api/fetchDataParking.js";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
  colorVerifyBtn: colorVerifyBtn,
  hoverVerifyBtn: hoverVerifyBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
  colorPrimaryhover: colorPrimaryhover,
};

function ImportData() {
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const navigateBack = (data) => {
    navigate("/" + data);
  };
  // import data ---
  //
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const [failedRow, setFailedRow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emptyCell, setEmptyCell] = useState(0);
  const [raw, setRaw] = useState([]);
  const initState = {
    data_per_page: 5,
  };
  const [search, setSearch] = useState(initState);

  const dataHeader = [
    {
      Header: "In Row",
      accessor: "no",
    },
    {
      Header: "Error Message",
      accessor: "errorMessage",
    },
  ];
  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    const row = [];

    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          // key: index,
          testdrive_date: "",
          time_start: item[1],
          time_end: item[2],
          name_surname: item[3],
          phone: item[4],
          email: item[5],
          birthday: item[6],
          gender: item[7],
          dealer_code: item[8],
          postcode: item[9],
          car_model_1: item[10],
          car_grade_1: item[11],
          car_model_2: item[12],
          car_grade_2: item[13],
        });
      }
    });
    await setImportData(row);
    // setTotalRow(rows.length - 1);
    setTotalRow(row.length);
    const emptyCells = [];

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (cell === null || cell === undefined || cell === "") {
          emptyCells.push({ row: i, column: j });
        }
      }
    }
    // setEmptyCell(emptyCells.length);
    // if (emptyCells.length > 0) {
    //   Swal.fire({
    //     title: "กรุณากรอกข้อมูลในไฟล์ให้ครบถ้วน",
    //     icon: "error",
    //     showCancelButton: true,
    //     confirmButtonText: "ยืนยัน",
    //     cancelButtonText: `ยกเลิก`,
    //     reverseButtons: true,
    //   });
    // }
  };

  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];
      //   setTotalRow(importData.length - 1);

      const dataToVerify = {
        importData: importData,
      };

      await setLoading(true);

      const response = await verifyDataImport(dataToVerify);

      await setLoading(false);
      if (response) {
        create += response.data.create;
        setTotalRow(response.data.total);
        const failedData = response.data.resultFail;
        failedData.map((item) => {
          failedArr.push(item);
        });

        setVerifySuccess(response.data.total);
        setVerifySkip(skip);
        setVerifyNew(create);
        setFailedRow(failedArr);
        failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);

        if (failedArr.length < 1) {
          Swal.fire({
            title: "ตรวจสอบข้อมูลสำเร็จ",
            icon: "success",
            confirmButtonText: "ตกลง",
          }).then(async (result) => {});
        }
      } else {
        error++;
        Swal.fire({
          title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
          icon: "error",
          confirmButtonText: "ตกลง",
        });
        return;
      }
    } catch (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
        reverseButtons: true,
      });
    }
  };

  const importDataExcel = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let success = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      setTotalRow(importData.length);

      await Swal.fire({
        title: "คุณต้องการ import ข้อมูลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const dataToVerify = {
            importData: importData,
          };
          await setLoading(true);
          const response = await postImportData(dataToVerify);
          await setLoading(false);
          if (response) {
            if (response.data.status === "success") {
              Swal.fire({
                title: "บันทึกข้อมูลเข้าสู่ระบบสำเร็จ",
                icon: "success",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  setIsVerify(false);
                  // navigate("/testdrive/schedule/booking");
                }
              });
            }
            success += response.data.success;
            skip += response.data.skip;
            // setVerifySuccess(success);
            // setVerifySuccess(response.data.createPos);
            setVerifySuccess(response.data.total);
            setVerifySkip(skip);
          } else {
            error++;
            Swal.fire({
              title: "กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ",
              icon: "error",
              confirmButtonText: "ตกลง",
            });
            return;
          }
        } else {
          return;
        }
      });
    } catch (error) {}
  };
  async function getDataFail() {
    try {
      const response = await fetchData(search);
      // เตรียมข้อมูลเพื่อแสดงในตาราง
      setRaw({
        ...response.data,
        data: response?.data.data.map((item, index) => {
          const no =
            (response.data.page_number - 1) * response.data.data_per_page +
            (index + 1);
          return {
            no: no,
            errorMessage: item.first_name,
          };
        }),
      });
    } catch (error) {}
  }
  useEffect(() => {
    getDataFail();
  }, []);
  // ---
  return (
    <>
      <>
        {isLoading ? <Loading /> : ""}
        <Header parentmenu={"Master Data"} />
        <Menu parentmenu={"MasterData"} activemenu={"AppealType"} />
        <div className="content-wrapper">
          <div className="container-fluid  pdb-page">
            <HeadTitle
              title="ประเภทการร้องเรียน"
              status={false}
              path={""}
              pathImport={""}
              pathExport={""}
            />
            <div className="box-white mt-4">
              {" "}
              <font className="font-weight-bold">
                <span className="m-0 fs-15 font-weight-bold ">
                  เพิ่มข้อมูลทีละหลายรายการ
                </span>
              </font>
              <div className="row justify-content-start align-items-center  mt-1">
                <div className="col-auto">
                  <p className="fs-15 font-weight-bold  mb-0">
                    กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                  </p>
                </div>
                <div className="col-auto mt-2 mt-md-0">
                  <Link
                    as={Link}
                    to={`${process.env.REACT_APP_BASE_URL}/testdrive/template/instructor-schedule`}
                    target="_blank"
                    className="btn-download text-white d-inline-block h-auto"
                    id="downloadBtn"
                    value="download"
                  >
                    Download Template Form
                  </Link>
                </div>
              </div>
              <div className="row justify-content-start  pt-3">
                <div className="col-12 mb-2 mb-lg-2">
                  <p className=" font-weight-bold ">
                    Upload File
                    <>
                      {" "}
                      <span className="mx-2">
                        {verifySuccess}/{totalRow}
                      </span>
                    </>
                  </p>
                  <span className="text-black mb-1 fs-13">
                    รอบรับไฟล์ .xlsx
                  </span>
                </div>
                <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                  <ImportExcel onImport={handleImport} className="" />
                </div>
                <div className="col-12 col-lg-3">
                  <button
                    className="btn-verify "
                    disabled={emptyCell > 0}
                    onClick={verifyData}
                  >
                    ตรวจสอบไฟล์
                  </button>
                </div>
              </div>{" "}
              <div className="col-12 mt-3">
                <p className="text-danger font-weight-bold">Error Message : </p>
              </div>
              {/*   failedRow.length > 0 เอาไว้เช้ค error import file */}
              <TableContent bgHeader={"#DB000E"}
                columns={dataHeader}
                data={raw.data || []}
                total={raw.total || 0}
                currentPage={raw.page_number || 0}
                dataPerPage={raw.data_per_page}
                handlerPerpage={(dataPerPage) =>
                  setSearch({
                    ...search,
                    data_per_page: dataPerPage,
                  })
                }
                handlerSort={(eSort) => {
                  console.log(eSort);
                  setSearch({
                    ...search,
                    [eSort.key]: eSort.direction,
                  });
                }}
                handlerChangePage={(pageNumber) => {
                  setSearch({
                    ...search,
                    page_number: pageNumber,
                  });
                }}
              />
              <Row className="d-flex mt-5">
                <Col xs={6} className="d-flex justify-content-start">
                  <button
                    className="btn-cancle ml-2 text-white  "
                    onClick={(e) => {
                      navigateBack("masterDataAppealType");
                    }}
                  >
                    Back
                  </button>{" "}
                  <button
                    className="btn-save ml-3"
                    disabled={isVerify}
                    onClick={(e) => {
                      importDataExcel();
                    }}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
}

export default ImportData;
