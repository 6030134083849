import { POST } from "../baseapi";
const routes = {
  login: "/api/admin/auth/login",
  refresh_token: "/api/admin/auth/refresh-token",
  forgot_password: "/api/admin/auth/forgot-password",
  reset_password: "/api/admin/auth/reset-password",
  check_token: "/api/admin/auth/check-token",
};

export const loginService = async (data) => {
  const url = routes.login;
  const result = await POST(url, data);
  if (result?.data?.role.role_name !== 'superadmin') {
    localStorage.setItem('wuuid', result?.data?.project_id)
  }
  
  return result;
};

export const forgotPasswordService = async (email) => {
  try {

    const url = routes.forgot_password;
    const jsonData = {
      email: email,
    };
    const result = await POST(url, JSON.stringify(jsonData));
    return result;
  } catch (error) {
    console.log(error)
    return error.response.data
  }
};

export const resetPasswordService = async (uuid, newPassword) => {
  const url = routes.reset_password;
  const jsonData = {
    token: uuid,
    newPassword: newPassword,
  };
  const result = await POST(url, JSON.stringify(jsonData));

  return result;
};

export const checkToken = async () => {
  const result = await POST(routes.check_token)
  return result
}