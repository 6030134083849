import React from "react";
import Header from "../template/Header";
import Menu from "../template/Menu";
import Footer from "../template/Footer";
import HeadTitle from "../template/HeadTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "../components/loading/Loading";
import Calendarpicker from "../components/calendars/Calendarpicker";
import CardDashboardData from "../components/cardDashMain/CardDashboard";
import { textPrimary, textSecond } from "../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../components/useDynamic/text_DynamicStyle";
import {
  textSearchBtn,
  textResetBtn,
  textCreateBtn,
  colorSearchBtn,
  colorResetBtn,
  colorCreateBtn,
  iconSearchBtn,
  iconResetBtn,
  iconCreateBtn,
  hoverSearchBtn,
  hoverResetBtn,
  hoverCreateBtn,
  textSaveBtn,
  textCancleBtn,
  colorSaveBtn,
  colorCancleBtn,
  hoverSaveBtn,
  hoverCancleBtn,
  colorEditBtns,
  hoverEditBtns,
} from "../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../components/useDynamic/Button_DynamicStyle";
import { fetchDashboardDataList } from "../api/Dashboard_api";
import { setTimeOutSync } from "./../utils/setTimeout";
function InfoDashboard() {
  useTextDynamicStyles(textPrimary, textSecond);
  useButtonDynamicStyles(
    textSearchBtn,
    textResetBtn,
    textCreateBtn,
    colorSearchBtn,
    colorResetBtn,
    colorCreateBtn,
    iconSearchBtn,
    iconResetBtn,
    iconCreateBtn,
    hoverSearchBtn,
    hoverResetBtn,
    hoverCreateBtn,
    textSaveBtn,
    textCancleBtn,
    colorSaveBtn,
    colorCancleBtn,
    hoverSaveBtn,
    hoverCancleBtn,
    colorEditBtns,
    hoverEditBtns
  );
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [inputForm, setInputForm] = useState({
    accountName: "",
    activeStatus: 0,
  });
  const [searchData, setSearchData] = useState({
    datefrom: null,
    dateto: null,
  });
  const [dataAllGhape, setDataAllGH] = useState([{}]);
  let [dateTime, setDatetime] = useState(null);
  let [dateYear, setDateyear] = useState(null);
  function setinput(inputname, value) {
    setInputForm((prev) => {
      return {
        ...prev,
        [inputname]: value,
      };
    });
  }

  const [dataUser, setDataUser] = useState({ countUser: 0, dateTime: null });
  const [inputDatestart, setInputDateStart] = useState("");
  const [inputDateend, setInputDateEnd] = useState("");

  function resetDataSearch() {
    setSearchData({ ...searchData, datefrom: null, dateto: null });
    setInputDateStart("");
    setInputDateEnd("");
    getDataDashboard();
  }

  const [showPopup, setShowPopup] = useState(false);

  const showReloadPopup = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", showReloadPopup);
    // return () => {
    //   window.removeEventListener("beforeunload", showReloadPopup);
    // };
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("tokenBackoffice")) {
      navigate("/login");
    }
    // getDataDashboard();
  }, []);

  async function getDataDashboard() {
    try {
      setLoading(true);

      setTimeOutSync(() => setLoading(false)).then(async () => {
        let response = await fetchDashboardDataList();
        setDateyear(response.year_txt);

        let dataUserMonth = response.monthly_data;
        let dataArrGHUser = [];
        for (let i = 0; i < dataUserMonth.length; i++) {
          dataArrGHUser.push({
            id: i + 1,
            month: dataUserMonth[i].month_txt,
            store: "test",
            lastUpdate: "xx/xx/xxxx",
            quantity: dataUserMonth[i].vistor_count,
          });
        }
        var format_date = new Date(
          moment(response.visitor_counter.last_updated).format("YYYY-MM-DD")
        );
        let dateData = format_date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        const parts = dateData.trim().split(" "); // Split the input string by spaces
        if (parts.length === 3) {
          const [day, month, year] = parts;
          const formattedDate = `${day}/${month}/${year}`;
          setDatetime(formattedDate);
          setDataUser({
            ...dataUser,
            countUser: response.visitor_counter.total,
            dateTime: formattedDate,
          });
        }

        setDataAllGH(dataArrGHUser);
      });
    } catch (error) {}
  }

  async function getDataDashboardSearch() {
    try {
      const startDate = new Date(searchData.datefrom);
      const endDate = new Date(searchData.dateto);

      if (searchData.dateto != null && endDate < startDate) {
        Swal.fire({
          icon: "warning",
          title: "กรุณาเลือกช่วงเวลาให้ถูกต้อง",
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          reverseButtons: true,
          showCancelButton: false,
        });
      } else {
        setLoading(true);

        setTimeOutSync(() => setLoading(false)).then(async () => {
          let response = await fetchDashboardDataList(searchData);
          if (response) {
            setDateyear(response.year_txt);

            let dataUserMonth = response.monthly_data;
            let dataArrGHUser = [];
            for (let i = 0; i < dataUserMonth.length; i++) {
              dataArrGHUser.push({
                id: i + 1,
                month: dataUserMonth[i].month_txt,
                store: "test",
                lastUpdate: "xx/xx/xxxx",
                quantity: dataUserMonth[i].vistor_count,
                // quantity: 30,
                //
              });
            }
            var format_date = new Date(
              moment(response.visitor_counter.last_updated).format("YYYY-MM-DD")
            );
            let dateData = format_date.toLocaleDateString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
            const parts = dateData.trim().split(" "); // Split the input string by spaces
            if (parts.length === 3) {
              const [day, month, year] = parts;
              const formattedDate = `${day}/${month}/${year}`;
              setDatetime(formattedDate);
              setDataUser({
                ...dataUser,
                countUser: response.visitor_counter.total,
                dateTime: formattedDate,
              });
            }

            setDataAllGH(dataArrGHUser);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ไม่พบข้อมูล",
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
              showCancelButton: false,
            }).then((res) => {
              if (res.isConfirmed) {
                resetDataSearch();
              }
            });

            // getDataDashboard();
          }
        });
      }
    } catch (error) {}
  }
  function ChangesetValueDateStart(value) {
    setInputDateStart(value);
    setSearchData({
      ...searchData,
      datefrom: moment(new Date(value)).format("YYYY-MM-DD"),
    });
  }

  function ChangesetValueDateEnd(value) {
    setInputDateEnd(value);
    setSearchData({
      ...searchData,
      dateto: moment(new Date(value)).format("YYYY-MM-DD"),
    });
  }
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Header parentmenu={"Dashboard"} />
      <Menu parentmenu={"Dashboard"} activemenu={"Dashboard"} />
      <div className="content-wrapper">
        <div className="container-fluid ">
          <HeadTitle
            title="Dashboard"
            subTitle="Dashboard / Dashboard หลัก"
            img={"assets/icons/ic-dashboard.png"}
            pathLink={""}
          />

          <div className="box-white">
            <i className="fas fa-search colorGreen02"></i>
            <font className="font-weight-bold fontReg font-size01"> ค้นหา</font>
            <div className="bd-line01"></div>
            <Row className="mt-3">
              <Col xs={6} md={3}>
                <p className="fontLight font-size03 colorGray01 font-weight-bold mb-2">
                  วันที่เริ่มต้น
                </p>

                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  ChangesetValueDate={ChangesetValueDateStart}
                  inputDate={inputDatestart}
                />
              </Col>
              <Col xs={6} md={3}>
                <p className="fontLight font-size03 colorGray01 font-weight-bold mb-2">
                  วันที่สิ้นสุด
                </p>

                <Calendarpicker
                  dataDate={null}
                  setDatePicker={setinput}
                  ChangesetValueDate={ChangesetValueDateEnd}
                  inputDate={inputDateend}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={6} className="d-flex justify-content-end">
                <button
                  className="btn-search"
                  onClick={() => getDataDashboardSearch()}
                >
                  <i className="fas fa-search"></i> ค้นหา
                </button>
              </Col>
              <Col xs={6}>
                <button className="btn-reset" onClick={() => resetDataSearch()}>
                  <i className="fas fa-undo"></i> เริ่มใหม่
                </button>
              </Col>
            </Row>
          </div>

          <Row className="mt-1">
            <div className="parent">
              <div className="div-left-card box-white">
                {" "}
                <p>
                  <span className="border-left-style px-1 font-weight-bold">
                    ปริมาณผู้เข้าชมเว็บไซต์
                  </span>{" "}
                </p>
                <Row className="text-center ">
                  <Col xs={12} md={12} lg={12} className="">
                    <h5 className="mb-5 font-weight-bold mt-5">จำนวนทั้งหมด</h5>
                    <span className=" font-weight-bold text-main">
                      {dataUser.countUser}
                    </span>{" "}
                    <span className="mb-5 font-weight-bold">คน</span>
                    <p className="mt-5">อัปเดตล่าสุด</p>
                    <p> {dataUser.dateTime}</p>
                  </Col>
                </Row>{" "}
              </div>
              <div className="div-right-card box-white">
                {" "}
                <CardDashboardData
                  dataTime={dateTime}
                  dataYear={dateYear}
                  dataUser={dataAllGhape}
                />{" "}
              </div>
            </div>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InfoDashboard;
