import { useEffect } from "react";
export function useButtonExpDynamicStyles(data) {
  useEffect(() => {
    // Loop through the `data` object using Object.entries()
    for (const [key, value] of Object.entries(data)) {
      // Create the CSS variable name dynamically
      const cssVariableName = `--${key}`;

      // Set the CSS variable on the root element
      document.documentElement.style.setProperty(cssVariableName, value);
    }
  }, [data]); // useEffect dependency on `data` so it runs when `data` changes
}
