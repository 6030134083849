import { DELETE, GET, POST, PUT } from "../baseapi"

const routes = {
    root_path: '/api/admin/subscription',
}

export const getLists = async () => {
    const url = routes.root_path
    const result = await GET(url)
    return result
}

export const getTransaction = async (query) => {
    const url = routes.root_path + '/list'
    const result = await GET(url, query)
    return result
}

export const getTransactionById = async (query) => {
    const url = '/api/admin/subscription/' + query
    const result = await GET(url)
    return result
}

export const update = async (query, obj) => {
    const url = '/api/admin/subscription/' + query
    const result = await PUT(url, obj)
    return result
}

export const create = async (
    obj
) => {
    const url = routes.root_path
    const result = await POST(url, obj)
    return result
}

// export const getListById = async (uuid) => {
//     const url = routes.root_path + '/' + uuid
//     const result = await GET(url)
//     return result
// }

// export const updateById = async (brand_th, brand_en, website_url, is_active, uuid) => {
//     const url = routes.root_path + '/' + uuid
//     const jsonData = {
//         brand_th, brand_en, website_url, is_active
//     }
//     const result = await PUT(url, jsonData)
//     return result
// }
// export const removeById = async (uuid) => {
//     const url = routes.root_path + '/' + uuid
//     const result = await DELETE(url)
//     return result
// }