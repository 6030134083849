import React, { useEffect, useState } from 'react'
import TableContent from '../../../../components/TableContent/TableContent';
import "./style.css"

export default function Permission({
    handleChange,
    options,
    currentValue
}) {

    const special = [
        {
            title: 'All',
            value: 0
        }
    ]
    const [raw, setRaw] = useState([]) // ตรงนี้จะเอาข้อมูลไปใส่ตามราง
    const [search, setSearch] = useState([]) // ค้นหา
    // ใช้สำหรับปรับจำนวนแสดงผลข้อมูลในตาราง
    const handlerPerpage = (perPage) => {
        setSearch({
            ...search,
            data_per_page: perPage
        })
    }

    // ใช้สำหรับปรับการเรียงลำดับในตาราง มากไปน้อย หรือ น้อยไปมาก
    const handlerSort = (perPage) => {
        console.log(perPage)
        setSearch({
            ...search,
            sort: perPage
        })
    }

    useEffect(() => {
        if (currentValue?.length > 0) {
            setRaw({
                data: [...special, ...currentValue]
            })
        } else {
            setRaw({
                data: [...special, ...options]
            })
        }

    }, [options, currentValue])

    // ใช้สำหรับเปลี่ยนหน้า
    const handlerChangePage = (page) => {
        setSearch({
            ...search,
            page_number: page
        })
    }

    const columns = [
        {
            Header: 'Feature',
            accessor: 'title',
        },
    ];

    return (
        <TableContent
            columns={columns}
            data={raw.data || []}
            total={raw.total || 1}
            handlerPerpage={handlerPerpage}
            handlerSort={handlerSort}
            currentPage={raw.page_number || 1}
            dataPerPage={raw.data_per_page || 1}
            handlerChangePage={handlerChangePage}
            onChange={(e) => handleChange(e)}
            check
            labelCheck="Subscription"
            checkPosition={'right'}
        />
    )
}
