import React, { createContext } from "react"
import { useState } from 'react'
import iconsuccess from "src/components/Sweetalert/icon-img/ic-success.png";
import iconwarning from "src/components/Sweetalert/icon-img/ic-warning.png";
import iconerror from "src/components/Sweetalert/icon-img/ic-error.png";
import CustomSweetAlert from "src/components/Sweetalert/index";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next"

export const PopupContext = createContext()

const PopupContextProvider = ({ children }) => {
    const { t } = useTranslation()
    const colorMap = {
        warning: "#F7BC0B",
        success: "#28CC7D",
        delete: "#E32A29",
    }
    const [popup, setPopup] = useState({
        status: 'warning',
        iconsuccess: iconsuccess,
        iconwarning: iconwarning,
        iconerror: iconerror,
        confirmBtnText: 'Close',
        cancelBtnText: 'Cancel',
        showCancel: false,
        colorBtnCancel: '#E0E0E0',
        onConfirm: () => { }
    })
    return (
        <>
            {
                popup?.show && (
                    <CustomSweetAlert
                        data={{
                            showCancel: popup.showCancel,
                            reverseButtons: true,
                            confirmBtnText: popup?.confirmBtnText || 'Ok',
                            cancelBtnText: popup?.cancelBtnText || 'Cancel',
                            icon: popup?.status,
                            title: popup?.status?.toUpperCase(),
                            message: t(`${popup?.message}`),
                            onConfirm: () => popup?.onConfirm(),
                            colorBorder: colorMap[`${popup.status}`],
                            colorBtnConfirm: colorMap[`${popup.status}`],
                            colorBtnCancel: popup.colorBtnCancel,
                        }}
                    />
                )
            }
            <PopupContext.Provider value={
                { popup, setPopup } // nosonar
            }>
                {children}
            </PopupContext.Provider>
        </>
    )
}


PopupContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PopupContextProvider