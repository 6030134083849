import React, { useEffect, useState } from "react";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import CustomSweetAlert from "../../../components/Sweetalert/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import {
  textPrimary,
  textSecond,
} from "../../../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../../../components/useDynamic/text_DynamicStyle";
import { DataDropdownBrand } from "../../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../../components/DropdownSearch/DropdownSearch.jsx";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
function AddEditData() {
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  useTextDynamicStyles(textConfig);
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    Subscription_id: "",
    Brand: "",
    Project: "",
    Name: "",
    Role_id: "",
    status: true,
    Phone: "",
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");
  console.log("editId : ", editId);
  const [showAlert, setShowAlert] = useState(false);
  // const [status, setStatus] = useState(editId ? "edit" : "create");
  const [status, setStatus] = useState("edit");
  const navigatePage = (page) => {
    navigate("/" + page);
  };
  const handleSubmit = () => {
    setShowAlert(true); // Show the alert when this function is called
  };
  const handleConfirm = (status) => {
    console.log(status); // Handle the confirmation
    setShowAlert(false); // Hide the alert after confirmation
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
    console.log(status); // Handle the cancellation
    setShowAlert(false); // Hide the alert after cancellation
  };
  return (
    <>
      {/* <Loading /> */}
      {isLoading ? <Loading /> : ""}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel: status === "success" ? false : true,
            reverseButtons: true,
            confirmBtnText:
              {
                edit: "Yes",
                success: "Close",
              }[status] || "Delete",
            cancelBtnText: "Cancel",
            icon: "",
            title:
              {
                edit: "WARNING",
                success: "SUCCESS",
              }[status] || "DELETE",
            message:
              {
                edit: "Do you want to save the changes?",
                success: "Data saved successfully",
              }[status] || "Are you sure you want to delete this data?",
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder:
              {
                edit: "#F7BC0B",
                success: "#28CC7D",
              }[status] || "#E32A29",
            colorBtnConfirm:
              {
                edit: "#F7BC0B",
                success: "#28CC7D",
              }[status] || "#E32A29",
            colorBtnCancel: "#E0E0E0",
          }}
        />
      )}
      <Header parentmenu={"Admin"} />
      <Menu parentmenu={"Admin"} activemenu={"Admin"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดข้อมูลผู้ใช้งานระบบหลังบ้าน"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Subscription ID</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Subscription_id"
                        placeholder="Subscription ID"
                        value={data.Subscription_id}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Subscription_id: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Brand</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Brand"}
                        value={999}
                        onChange={(e) => {
                          setSearch({
                            ...search,
                            brand: e.value,
                          });
                        }}
                        options={DataDropdownBrand}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Project"}
                        value={999}
                        onChange={(e) => {
                          setSearch({
                            ...search,
                            project: e.value,
                          });
                        }}
                        options={DataDropdownBrand}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Name</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Name"
                        placeholder="Name"
                        value={data.Name}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Name: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Role</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Role"}
                        value={999}
                        onChange={(e) => {
                          setSearch({
                            ...search,
                            role: e.value,
                          });
                        }}
                        options={DataDropdownBrand}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Phone</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Phone"
                        placeholder="Phone"
                        value={data.Phone}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Phone: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("masterDataAdmin");
                  }}
                >
                  Back
                </button>{" "}
                <button
                  className="btn-save"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
