import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  headPrimaryColor,
  headSecondColor,
  mainHeaderbg,
  headColor,
} from "../components/ThemeConfig/headerConfig";
import PropTypes from "prop-types";
import {
  colortExpBtn,
  colorBorExpBtn,
  hoverExpBtn,
  colortCreBtn,
  colorBorCreBtn,
  hoverCreBtn,
  colortImtBtn,
  colorBorImtBtn,
  hoverBorBtn,
  imgExport,
  imgImport,
  imgCreate,
} from "../components/ThemeConfig/btnExport";
import { useHeaderDynamicStyles } from "../components/useDynamic/header_DynamicStyle";
import { useButtonExpDynamicStyles } from "../components/useDynamic/ButtonExp_DynamicStyle";
import { textPrimary, textSecond } from "../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../components/useDynamic/text_DynamicStyle";
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
const headConfig = {
  headPrimaryColor: headPrimaryColor,
  headSecondColor: headSecondColor,
  mainHeaderbg: mainHeaderbg,
  headColor: headColor,
};
const btnConfig = {
  colortExpBtn: colortExpBtn,
  colorBorExpBtn: colorBorExpBtn,
  hoverExpBtn: hoverExpBtn,
  colortCreBtn: colortCreBtn,
  colorBorCreBtn: colorBorCreBtn,
  hoverCreBtn: hoverCreBtn,
  colortImtBtn: colortImtBtn,
  colorBorImtBtn: colorBorImtBtn,
  hoverBorBtn: hoverBorBtn,
  imgExport: imgExport,
  imgImport: imgImport,
  imgCreate: imgCreate,
};
function Header(props) {
  const navigate = useNavigate();
  useTextDynamicStyles(textConfig);
  useHeaderDynamicStyles(headConfig);
  useButtonExpDynamicStyles(btnConfig);
  const navigatePage = (data) => {
    navigate("/" + data);
  };
  return (
    <div className="box-headText ">
      <Row>
        <Col xs={6} md={6} xl={6} className="d-flex align-items-center">
          <h5 className="text-main font-weight-bold  ">{props.title} </h5>
        </Col>{" "}
        <Col xs={3} md={3} xl={3} className="d-flex justify-content-end"></Col>
        <Col
          xs={3}
          md={3}
          xl={3}
          className={
            props.status === true
              ? "d-flex justify-content-end align-items-center "
              : "d-none"
          }
        >
          {" "}
          <button className={"btn-export "}>
            {/* <img src={imgExport} alt="ExportFiles Icon" className="ic-add" /> */}
            <span className=" text-white ">Export</span>
          </button>{" "}
          <button
            className={"btn-import ml-2"}
            onClick={() => navigatePage(props.pathImport)}
          >
            {/* <img src={imgExport} alt="ExportFiles Icon" className="ic-add" /> */}
            <span className=" text-white ">Import</span>
          </button>{" "}
          <button
            onClick={() => navigatePage(props.path)}
            className={"btn-create ml-2 "}
          >
            <img src={imgCreate} alt="ExportFiles Icon" className="ic-add" />
            <span className=" text-white ">Create</span>
          </button>
        </Col>
      </Row>
    </div>
  );
}
Header.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.bool,
  path: PropTypes.string,
  pathImport: PropTypes.string,
  pathExport: PropTypes.string,
};
export default Header;
