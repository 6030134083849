import iconDashboard from "../../assets/icon-menu/ic-dashboard.png";
import iconMaster from "../../assets/icon-menu/ic-master.png";
import iconSubscri from "../../assets/icon-menu/ic-subscri.png";
import iconAdmin from "../../assets/icon-menu/ic-admin.png";
import iconTransac from "../../assets/icon-menu/ic-transac.png";
import iconReport from "../../assets/icon-menu/ic-report.png";
import iconPassword from "../../assets/icon-menu/ic-password.png";
import iconLogout from "../../assets/icon-menu/ic-logout.png";
import iconUnit from "../../assets/icon-menu/ic-unit.png";
import iconResident from "../../assets/icon-menu/ic-resident.png";
// icon active
import iconMasterActive from "../../assets/icon-menu/ic-ac-master.png";
import iconSubscriActive from "../../assets/icon-menu/ic-ac-subscri.png";
import iconAdminActive from "../../assets/icon-menu/ic-ac-admin.png";
import iconUnitActive from "../../assets/icon-menu/ic-ac-unit.png";
import iconResidentActive from "../../assets/icon-menu/ic-ac-resident.png";


// ###### Note this is backend mapping menu
// export const WEB_MENUES = [
//   { name: "Super Dashboard", code: "bo_sup_dashboard", type: "standard", platform:'backoffice' },
//   { name: "Brand Dashboard", code: "bo_bra_dashboard", type: "standard", platform:'backoffice' },
//   { name: "Project Dashboard", code: "bo_pro_dashboard", type: "standard", platform:'backoffice' },
//   { name: "Banner", code: "bo_banner", type: "standard", platform:'backoffice' },
//   { name: "Chat", code: "bo_chat", type: "standard", platform:'backoffice' },
//   { name: "Emergentcy Management", code: "bo_emergency", type: "standard", platform:'backoffice' },
//   { name: "Parcel Management", code: "bo_parcel", type: "standard", platform:'backoffice' },
//   { name: "Master Residence type", code: "bo_m_residence", type: "standard", platform:'backoffice' },
//   { name: "Master Brand", code: "bo_m_brand", type: "standard", platform:'backoffice' },
//   { name: "Master Project", code: "bo_m_project", type: "standard", platform:'backoffice' },
//   { name: "Master Resident type", code: "bo_m_resident", type: "standard", platform:'backoffice' },
//   { name: "Approve Project", code: "bo_approve_project", type: "standard", platform:'backoffice' },
//   { name: "Request Project", code: "bo_request_project", type: "standard", platform:'backoffice' },
//   { name: "Subscription", code: "bo_subscription", type: "standard", platform:'backoffice' },
//   { name: "Portal Banner", code: "bo_banner_portal", type: "standard", platform:'backoffice' },
//   { name: "User Management", code: "bo_user", type: "standard", platform:'backoffice' },
//   { name: "Terms and Conditions", code: "bo_terms", type: "standard", platform:'backoffice' },
//   { name: "Privacy & Policy", code: "bo_policy", type: "standard", platform:'backoffice' },
//   { name: "Rent & Sell", code: "bo_rent_sell", type: "standard", platform:'backoffice' },
//   { name: "New & Event Portal", code: "bo_news_portal", type: "standard", platform:'backoffice' },
//   { name: "New & Event Project", code: "bo_news_project", type: "standard", platform:'backoffice' },
// ];


export const originalMenus = [
  {
    text: "Dashboard",
    name: "Dashboard",
    menu_code: "bo_sup_dashboard",
    icon: iconDashboard,
    iconActive: iconDashboard,
    status: true,
    path: "/infoDashboard",
    subMenu: [],
  },
  {
    text: "Master Data",
    name: "MasterData",
    menu_code: "bo_m_residence",
    icon: iconMaster,
    iconActive: iconMasterActive,
    status: true,
    path: "/masterDataResidence",
    subMenu: [
      {
        text: "ประเภทที่อยู่อาศัย",
        menu_code: "bo_m_residence",
        name: "Residence",
        status_sub: false,
        path: "/masterDataResidence",
      },
      {
        text: "แบรนด์",
        name: "Brand",
        menu_code: "bo_m_brand",
        status_sub: false,
        path: "/masterDataBrand",
      },
      {
        text: "โครงการ",
        name: "Project",
        menu_code: "bo_m_project",
        status_sub: false,
        path: "/masterDataProject",
      },
      {
        text: "ประเภทผู้อยู่อาศัย",
        name: "ResidentType",
        status_sub: false,
        path: "/masterDataResidentType",
      },
      {
        text: "ประเภทการแจ้งซ่อม",
        name: "RepairType",
        status_sub: false,
        path: "/masterDataRepairType",
      },
      {
        text: "ประเภทการร้องเรียน",
        name: "AppealType",
        status_sub: false,
        path: "/masterDataAppealType",
      },
    ],
  },
  {
    text: "Subscription",
    name: "Subscription",
    menu_code: "bo_subscription",
    icon: iconSubscri,
    iconActive: iconSubscriActive,
    status: true,
    path: "/masterDataSubscription",
    subMenu: [],
  },
  {
    text: "Resident",
    name: "Resident",
    menu_code: "bo_m_resident",
    icon: iconResident,
    iconActive: iconResidentActive,
    status: false,
    path: ``,
    subMenu: [],
  },
  {
    text: "Admin",
    name: "Admin",
    menu_code: "bo_subscription",
    icon: iconAdmin,
    iconActive: iconAdminActive,
    status: true,
    path: "/masterDataAdmin",
    subMenu: [],
  },
  {
    text: "Transaction",
    name: "Transaction",
    menu_code: "transaction",
    icon: iconTransac,
    iconActive: iconLogout,
    status: true,
    path: "/infoTransaction",
    subMenu: [],
  },
  {
    text: "Report",
    name: "Report",
    menu_code: "report",
    icon: iconReport,
    iconActive: iconReport,
    status: true,
    path: "/infoReport",
    subMenu: [],
  },
  {
    text: "Password",
    name: "Password",
    menu_code: "password",
    icon: iconPassword,
    iconActive: iconPassword,
    status: true,
    path: "/infoPassword",
    subMenu: [],
  },
  {
    text: "Logout",
    name: "Logout",
    icon: iconLogout,
    iconActive: iconLogout,
    status: true,
    path: "",
    subMenu: [],
  },
];


export const workspaceMenus = (uuid) => [
  {
    text: "Dashboard",
    name: "Dashboard",
    menu_code: "dashboard",
    icon: iconDashboard,
    iconActive: iconDashboard,
    status: true,
    path: `/workspace/${uuid}`,
    subMenu: [],
  },
  {
    text: "Master Data",
    name: "MasterData",
    menu_code: 'master_data_residence_type',
    icon: iconMaster,
    iconActive: iconMasterActive,
    status: true,
    path: `/workspace/${uuid}/master_data`,
    subMenu: [
      {
        text: "ประเภทที่อยู่อาศัย",
        name: "Residence",
        status_sub: false,
        path: "/masterDataResidence",
      },
      {
        text: "แบรนด์",
        name: "Brand",
        status_sub: false,
        path: "/masterDataBrand",
      },
      {
        text: "โครงการ",
        name: "Project",
        status_sub: false,
        path: "/masterDataProject",
      },
      {
        text: "ประเภทผู้อยู่อาศัย",
        name: "ResidentType",
        status_sub: false,
        path: "/masterDataResidentType",
      },
      {
        text: "ประเภทการแจ้งซ่อม",
        name: "RepairType",
        status_sub: false,
        path: "/masterDataRepairType",
      },
      {
        text: "ประเภทการร้องเรียน",
        name: "AppealType",
        status_sub: false,
        path: "/masterDataAppealType",
      },
    ],
  },
  {
    text: "Unit Management",
    name: "Unit Management",
    menu_code: "dashboard",
    icon: iconUnit,
    iconActive: iconUnitActive,
    status: true,
    path: `/workspace/${uuid}/masterDataUnitManagement`,
    subMenu: [],
  },

  {
    text: "Resident",
    name: "Resident",
    icon: iconResident,
    iconActive: iconResidentActive,
    status: true,
    path: `/workspace/${uuid}/masterDataResident`,
    subMenu: [],
  },
  {
    text: "Admin",
    name: "Admin",
    menu_code: "user_management",
    icon: iconAdmin,
    iconActive: iconAdminActive,
    status: true,
    path: "/masterDataAdmin",
    subMenu: [],
  },
  {
    text: "Transaction",
    name: "Transaction",
    menu_code: "transaction",
    icon: iconTransac,
    iconActive: iconTransac,
    status: true,
    path: "/infoTransaction",
    subMenu: [],
  },
  {
    text: "Report",
    name: "Report",
    menu_code: "report",
    icon: iconReport,
    iconActive: iconReport,
    status: true,
    path: "/infoReport",
    subMenu: [],
  },
  {
    text: "Password",
    name: "Password",
    menu_code: "password",
    icon: iconPassword,
    iconActive: iconPassword,
    status: true,
    path: "/infoPassword",
    subMenu: [],
  },
  {
    text: "Logout",
    name: "Logout",
    icon: iconLogout,
    iconActive: iconLogout,
    status: true,
    path: "",
    subMenu: [],
  },
];
