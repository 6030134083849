import { createContext, useState } from "react";
import { checkToken } from "src/api/adminAuthRoutes"
// Create the user context
export const GlobalContext = createContext({
    user: null,
    role: '',
    permission: []
});

// Define the GlobalProvider component
const GlobalProvider = ({ children }) => {
    const [role, setRole] = useState('superadmin');
    const [permission, setPerm] = useState([]);

    const fetchUserInfo = async (page) => {
        const response = await checkToken()
        console.log(response?.user?.role.role_name)
        console.log(`%c\n\n> Logging fetch user information  "${page}"\n\n`, `background: #00ff00; color: #000; font-size: 12px;`);
        setRole(response?.user?.role.role_name)
        setPerm(response?.user?.role?.menus)
    }

    return (
        <GlobalContext.Provider value={{ fetchUserInfo, role, setPerm, setRole, permission }}>{children}</GlobalContext.Provider>
    );
}

export default GlobalProvider;
