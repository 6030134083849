export const DataDropdownStatus = [
  { value: 999, label: "All" },
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];

export const DataDropdownBrand = [
  // { value: 0, label: "" },
  { value: 1, label: "Land & Houses" },
  { value: 2, label: "Sansiri" },
  { value: 3, label: "SC Asset" },
];

export const DataDropdownPermissionType = [
  { value: 0, label: "ทั้งหมด" },
  { value: 1, label: "Super Admin" },
  { value: 2, label: "Admin" },
];
