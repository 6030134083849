import axios from "axios";
import Swal from "sweetalert2";
import "../components/swalStyle/customStyle.css";
import { getInstance } from "./baseapi";

const dataToken = localStorage.getItem("tokenBackoffice");

const URL = process.env;

const instance = getInstance();

export const fetchDashboardDataList = async (queryparams) => {
  try {
    const rs = await instance.get(`/dashboard`, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
      params: queryparams,
    });

    if (rs.data.status.toLowerCase() == "success") {
      return rs.data.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    throw error;
  }
};
