import React from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ChartBar({ chartPromotionData }) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                yAlign: "bottom",
            },
        },
    };
    
    return (
        <Bar 
            data={chartPromotionData}
            options={options}
        />
    );
}

// Define propTypes for validation
ChartBar.propTypes = {
    chartPromotionData: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        datasets: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                data: PropTypes.arrayOf(PropTypes.number).isRequired,
                backgroundColor: PropTypes.string,
            })
        ).isRequired,
    }).isRequired,
};

export default ChartBar;
