import { DELETE, GET, POST, PUT } from "../baseapi"

const routes = {
    root_path: '/api/admin/project',
}
export const getLists = async (search) => {
    const url = routes.root_path
    const result = await GET(url, search)
    return result
}

export const create = async (
    data
) => {
    const url = routes.root_path
    const formData = new FormData()

    formData.append('brand_id', data?.brandName)
    formData.append('project_th', data?.Project_TH)
    formData.append('project_en', data?.Project_EN)
    // formData.append('email', data?.email ?? '')
    // formData.append('house_number', data?.house_number ?? '')
    // formData.append('moo', data?.moo ?? '')
    // formData.append('soi', data?.soi ?? '')
    // formData.append('tambon_id', data?.tambon_id ?? '')
    // formData.append('amphure_id', data?.amphure_id ?? '')
    // formData.append('province_id', data?.province_id ?? '')
    // formData.append('zip_code', data?.zip_code ?? '')
    formData.append('is_active', data?.status === true ? 1 : 0)
    if (data.image) {
        formData.append('file', data.image[0]?.file)
    }
    const result = await POST(url, formData)
    return result
}

export const getListById = async (uuid) => {
    const url = routes.root_path + '/' + uuid
    const result = await GET(url)
    return result
}

export const updateById = async (id,
    data
) => {
    const url = routes.root_path + '/' + id
    const formData = new FormData()

    formData.append('brand_id', data?.brandName)
    formData.append('project_th', data?.Project_TH)
    formData.append('project_en', data?.Project_EN)
    // formData.append('email', data?.email ?? '')
    // formData.append('house_number', data?.house_number ?? '')
    // formData.append('moo', data?.moo ?? '')
    // formData.append('soi', data?.soi ?? '')
    // formData.append('tambon_id', data?.tambon_id ?? '')
    // formData.append('amphure_id', data?.amphure_id ?? '')
    // formData.append('province_id', data?.province_id ?? '')
    // formData.append('zip_code', data?.zip_code ?? '')
    formData.append('is_active', data?.status === true ? 1 : 0)
    if (data.image) {
        formData.append('file', data.image[0]?.file)
    }
    const result = await PUT(url, formData)
    return result
}

export const removeById = async (uuid) => {
    const url = routes.root_path + '/' + uuid
    const result = await DELETE(url)
    return result
}