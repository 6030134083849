import { React, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import {
  bannerBgColor,
  tabMenuColor,
  titleBanner,
  sidebarColor,
  logoWeb,
  tabMenuActionColor,
  textColor,
  textColorActive,
} from "../components/ThemeConfig/menuConfig";
import { useMenuDynamicStyles } from "../components/useDynamic/menu_DynamicStyles";
import { fetchProfileDataList } from "../api/User_api";
import Loading from "../components/loading/Loading";
import { setTimeOutSync } from "../utils/setTimeout";
import { listData } from "../components/context/dataMenu";
import PropTypes from "prop-types";
const menuConfig = {
  bannerBgColor: bannerBgColor,
  tabMenuColor: tabMenuColor,
  titleBanner: titleBanner,
  sidebarColor: sidebarColor,
  logoWeb: logoWeb,
  tabMenuActionColor: tabMenuActionColor,
  textColor: textColor,
  textColorActive: textColorActive,
};
function Menu({ parentmenu, activemenu }) {
  useMenuDynamicStyles(menuConfig);
  const { listMenu, setListMenu } = useContext(listData);

  const getListMenu = listMenu.map((item) => {
    return {
      isopen: item.name === parentmenu ? true : false,
    };
  });

  const [isLoading, setLoading] = useState(false);
  const [dataUserLocal, setDataUserLocal] = useState(
    localStorage.getItem("UserData")
  );
  const [dataProfile, setDataProfile] = useState("");
  const [sideopen, setsideopen] = useState(getListMenu);
  const navigate = useNavigate();
  useEffect(() => {
    let userAgent = navigator.userAgent;
    let browserName;
    if (
      userAgent.includes("android") ||
      userAgent.includes("iPhone") ||
      userAgent.includes("iPad")
    ) {
      browserName = "mobile";
    } else if (userAgent.includes("Linux")) {
      browserName = "tablet";
    }

    if (
      (browserName === "mobile" || browserName === "tablet") &&
      window.innerWidth < 1334
    ) {
      $("#bodyid").removeClass("sidebar-open");
      $("#bodyid").addClass("sidebar-closed sidebar-collapse");
      $("#sidetest").addClass("d-none");
    }
    // getDataProfile();
  }, []);
  function menuclick(e, index) {
    if (e.target.outerHTML.includes("headermenu")) {
      console.log("b4" + sideopen[index].isopen);
      let currentopen = sideopen;
      currentopen[index].isopen = !sideopen[index].isopen;
      console.log(currentopen[index].isopen);
      setsideopen([...currentopen]);
    }
  }
  async function logoutData() {
    sessionStorage.removeItem("tokenBackoffice");
    localStorage.removeItem("tokenBackoffice");
    localStorage.removeItem("SaveLogin");
    navigate("/login");
  }
  return (
    <>
      {" "}
      {isLoading ? <Loading /> : ""}
      <aside className="main-sidebar sidebar-primary elevation-4" id="sidetest">
        <div className="brand-link  py-2">
          <div className="row">
            <div className="col-4  ">
              {/* <img src={logoWeb} width="65px" className="ml-4-style" /> */}
            </div>
            <div className="col-8 align-self-center">
              <span className="brand-text text-sm font-weight-bold text-black text-shadow-nm line-height01">
                Logo
                {/* {titleBanner} */}
              </span>
            </div>
          </div>
        </div>

        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2 ">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              // role="menu"
              data-accordion="false"
            >
              {listMenu.map((item, index) => (
                <li
                  onClick={(event) => menuclick(event, index)}
                  key={index}
                  className={`nav-item ${
                    sideopen[index].isopen ? "menu-is-opening menu-open" : ""
                  } `}
                >
                  {item.subMenu && item.subMenu.length === 0 ? (
                    item.name === "Logout" ? (
                      <div
                        className="nav-link "
                        onClick={() => logoutData()}
                        role="button" // Specifies the role of the element as a button
                        tabIndex="0" // Makes the div focusable via keyboard
                        onKeyPress={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            logoutData(); // Triggers the function when Enter or Space is pressed
                          }
                        }}
                      >
                        {" "}
                        <img
                          src={
                            activemenu === item.name
                              ? item.iconActive
                              : item.icon
                          }
                          width="16px"
                          className="mr-2 mbs-4 mg-3-style"
                        />{" "}
                        <p
                          className={`nav-link headermenu ${
                            parentmenu === item.name
                              ? "font-weight-bold headermenu text-white"
                              : "font-weight-bold headermenu text-black"
                          } `}
                        >
                          {item.text}
                        </p>
                      </div>
                    ) : (
                      <Link
                        as={Link}
                        to={item.path}
                        className={` ${
                          item.status === true ? "nav-link " : "d-none"
                        }   ${activemenu === item.name ? "active" : ""}`}
                      >
                        {" "}
                        <img
                          src={
                            activemenu === item.name
                              ? item.iconActive
                              : item.icon
                          }
                          width="16px"
                          className="mr-2 mbs-4 mg-3-style"
                        />{" "}
                        <p
                          className={`nav-link headermenu ${
                            parentmenu === item.name
                              ? "font-weight-bold headermenu text-white"
                              : "font-weight-bold headermenu text-black"
                          } `}
                        >
                          {item.text}
                        </p>
                      </Link>
                    )
                  ) : (
                    <div
                      className={`${
                        item.status === true ? "nav-link headermenu " : "d-none"
                      }  ${parentmenu === item.name ? "active" : ""} `}
                    >
                      <img
                        src={
                          parentmenu === item.name ? item.iconActive : item.icon
                        }
                        width="16px"
                        className="mr-2 mbs-4 mg-3-style"
                      />{" "}
                      <p
                        className={`nav-link headermenu ${
                          parentmenu === item.name
                            ? "font-weight-bold headermenu text-white"
                            : "font-weight-bold headermenu text-black"
                        } `}
                      >
                        {item.text}
                        <i
                          className={`right fas fa-angle-left headermenu ${
                            sideopen[index].isopen ? "rotate-90" : "rotate0"
                          }`}
                        />
                      </p>{" "}
                    </div>
                  )}

                  {item.subMenu && item.subMenu.length > 0 && (
                    <ul className="nav nav-treeview">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex} className="nav-item">
                          <Link
                            to={subItem.path} // Use the correct path for each submenu item
                            className={`nav-link d-flex align-items-center ${
                              activemenu === subItem.name ? "active" : ""
                            }`}
                          >
                            <i
                              className={`far fa-circle nav-icon ${
                                activemenu === subItem.name
                                  ? "active"
                                  : "navi-color"
                              }`}
                            />
                            <p
                              className={`${
                                activemenu === subItem.name
                                  ? "text-white"
                                  : "text-black"
                              }`}
                            >
                              {subItem.text}
                            </p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
Menu.propTypes = {
  parentmenu: PropTypes.string.isRequired, // or PropTypes.string if not required
  activemenu: PropTypes.string.isRequired, // or PropTypes.string if not required
};
export default Menu;
