import React, { useEffect, useState } from "react";
import Header from "../../template/Header";
import Menu from "../../template/Menu";
import Footer from "../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import HeadTitle from "../../template/HeadTitle";
import { DataDropdownStatus } from "../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch.jsx";
import { Card } from "react-bootstrap";
import InputData from "../../components/Input/index";
import {
  colorSearchBtn,
  colorResetBtn,
  iconSearchBtn,
  iconResetBtn,
  hoverSearchBtn,
  hoverResetBtn,
} from "../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../components/useDynamic/Button_DynamicStyle";
// Example fetch
import { fetchData } from "../../api/fetchDataParking.js";
import ButtonEdit from "../../components/button/edit/Edit.jsx";
import TableContent from "../../components/TableContent/TableContent.jsx";
import { getLists } from "../../api/projectRoutes"
const buttonConfig = {
  colorSearchBtn: colorSearchBtn,
  colorResetBtn: colorResetBtn,
  iconSearchBtn: iconSearchBtn,
  iconResetBtn: iconResetBtn,
  hoverSearchBtn: hoverSearchBtn,
  hoverResetBtn: hoverResetBtn,
};
function MasterData() {
  useButtonDynamicStyles(buttonConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    name_th: null,
    name_en: null,
    created_at: null,
    updated_at: null,
  });
  // table
  const [raw, setRaw] = useState([]);
  const initState = {
    data_per_page: 5,
  };
  const [search, setSearch] = useState(initState);

  const dataHeader = [
    {
      Header: "Project ID",
      accessor: "project_id",
      sorted: true,
    },
    {
      Header: "Brand Name (EN)",
      accessor: "brandname_en",
    },
    {
      Header: "Project Name (EN)",
      accessor: "projectname_en",
    },
    {
      Header: "Project Name (TH)",
      accessor: "projectname_th",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  // -----
  useEffect(() => {
    if (!localStorage.getItem("tokenBackoffice")) {
      navigate("/login");
    }
    getTableData();
  }, [search.data_per_page, search.sorted]);
  function resetDataSearch() {
    // setSearch((prevSearch) => ({ ...prevSearch, keyword: "", isactive: 999 }));
    search.keyword = null;
    search.is_active = 999;
    getTableData();
  }

  async function getTableData() {
    try {
      let data = {};
      for (let key in search) {
        // Check if the key is `is_active` to apply a transformation
        if (key === "is_active") {
          data[key] =
            search[key] === 1 ? true : search[key] === 2 ? false : null; // Apply condition to `is_active`
        } else {
          data[key] = search[key]; // Copy other properties directly
        }
      }
      const response = await getLists(search);
      // เตรียมข้อมูลเพื่อแสดงในตาราง
      setRaw({
        ...response.data,
        total: response.total,
        pageNumber: response.page_number,
        dataPerPage: raw.dataPerPage || 10,
        data: response?.data.data.map((item, index) => {
          return {
            project_id: item.project_id,
            brandname_en: item.brand.brand_th,
            projectname_en: item.project_en,
            projectname_th: item.project_th,
            status: item.is_active ? (
              <span className="text-success">Activated</span>
            ) : (
              <span className="text-danger">Inactivated</span>
            ), action: (
              <ButtonEdit path={"AddEditMasterProject"} id={`${item.uuid}`} />
            ),
          };
        }),
      });
    } catch (error) { }
  }

  return (
    <>
      {isLoading ? <Loading /> : ""}

      <Header parentmenu={"Master Data"} />
      <Menu parentmenu={"MasterData"} activemenu={"Project"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="ตารางข้อมูลโครงการ"
            status={true}
            path={"AddEditMasterProject"}
            pathImport={"ImportDataProject"}
            pathExport={""}
          />

          <div className="box-white mt-5">
            <Card>
              <Card.Body>
                {" "}
                <Row className="">
                  <Col xs={6} md={4}>
                    <p className="fs-15 font-weight-bold mb-1">Search</p>
                    <InputData
                      type="text"
                      className="inp-data  "
                      id="keyword"
                      placeholder="Search"
                      value={search.search || ""}
                      onChange={(e) =>
                        setSearch({
                          ...search,
                          search: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <p className="fs-15 font-weight-bold mb-1">Status</p>
                    <DropdownSearch
                      placeholder={"Status"}
                      value={search.is_active ? search.is_active : 999}
                      onChange={(e) => {
                        setSearch({
                          ...search,
                          is_active: e.value,
                        });
                      }}
                      options={DataDropdownStatus}
                    />
                  </Col>{" "}
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button
                      className="btn-search"
                      onClick={() => getTableData()}
                    >
                      {/* <i className="fas fa-search"></i>  */}
                      Search
                    </button>
                  </Col>
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button
                      className="btn-reset"
                      onClick={() => resetDataSearch()}
                    >
                      {/* <i className="fas fa-undo"></i>  */}
                      Clear
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <TableContent
              columns={dataHeader}
              data={raw.data || []}
              total={raw.total || 1}
              currentPage={raw.page_number || 1}
              dataPerPage={Number(raw.dataPerPage)}
              handlerPerpage={(dataPerPage) => {
                setSearch({
                  ...search,
                  data_per_page: dataPerPage,
                })
                setRaw({
                  ...raw,
                  dataPerPage: dataPerPage
                })
              }
              }
              handlerSort={(eSort) => {
                console.log(eSort);
                setSearch({
                  ...search,
                  [eSort.key]: eSort.direction,
                });
              }}
              handlerChangePage={(pageNumber) => {
                setSearch({
                  ...search,
                  page_number: pageNumber,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MasterData;
