import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import CustomSweetAlert from "../../../components/Sweetalert/index";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
function AddEditData() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");

  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    Resident_ID: "",
    Resident_EN: "",
    Resident_TH: "",
    status: true,
  });
  const [error, setError] = useState({
    error_EN: false,
    error_TH: false,
  });
  const [status, setStatus] = useState("create");
  const [showAlert, setShowAlert] = useState(false);

  const navigatePage = (page) => {
    navigate("/" + page);
  };
  const inputSetData = (value, language) => {
    const englishOnly = value.replace(/[^A-Za-z0-9\s]/g, "");
    const thaiOnly = value.replace(/[^ก-๙0-9\s]/g, "");
    if (language === "en") {
      setData((prevData) => ({
        ...prevData,
        Resident_EN: englishOnly,
      }));
    } else {
      setData({
        ...data,
        Resident_TH: thaiOnly,
      });
    }
    // error_EN: language === "en" && englishOnly === "" ? true : false,
    // error_TH: language !== "en" && thaiOnly === "" ? true : false,
    setError((prevError) => {
      let error_EN;
      let error_TH;

      if (language === "en") {
        error_EN = englishOnly === "";
      } else if (thaiOnly) {
        error_EN = false;
      } else {
        error_EN = prevError.error_EN;
      }

      if (language !== "en") {
        error_TH = thaiOnly === "";
      } else if (englishOnly) {
        error_TH = false;
      } else {
        error_TH = prevError.error_TH;
      }

      return {
        ...prevError,
        error_EN: error_EN,
        error_TH: error_TH,
      };
    });
  };
  const handleSubmit = () => {
    setShowAlert(true); // Show the alert when this function is called
  };
  const handleConfirm = async (status) => {
    console.log(status); // Handle the confirmation
    setShowAlert(false); // Hide the alert after confirmation
    try {
      // const response = editId
      //   ? await updateById(editId, data)
      //   : await create(data);
      let response = 0;
      if (response === 0) {
        setShowAlert(true);
        setStatus("success");
        if (status === "Close") {
          navigatePage("masterDataResidentType");
        }
      }
      console.log("response data : ", response.data);
    } catch (error) {}
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
    console.log(status); // Handle the cancellation
    setShowAlert(false); // Hide the alert after cancellation
    if (!editId) {
      // setData({
      //   Residence_ID: "",
      //   Residence_EN: null,
      //   Residence_TH: null,
      //   status: true,
      // });
      setError({
        error_EN: false,
        error_TH: false,
      });
    }
  };
  const getDataByID = async () => {
    try {
      // const response = await getListById(editId);
      let response = 0;
      if (response === 0) {
        setStatus("edit");
        // if (response) {
        setData({
          ...data,
          Residence_ID: "",
          Residence_EN: null,
          Residence_TH: null,
          status: true,
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (editId) {
      getDataByID();
    }
  }, []);
  return (
    <>
      {isLoading ? <Loading /> : ""}{" "}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel: status === "success" ? false : true,
            reverseButtons: true,
            confirmBtnText:
              {
                create: "Yes",
                edit: "Yes",
                success: "Close",
              }[status] || "Delete",
            cancelBtnText: "Cancel",
            icon: "",
            title:
              {
                create: "WARNING",
                edit: "WARNING",
                success: "SUCCESS",
              }[status] || "WARNING",
            message:
              {
                create: "คุณต้องการบันทึกหรือไม่?",
                edit: "คุณต้องการบันทึกการเปลี่ยนแปลงหรือไม่?",
                success: "Data saved successfully",
              }[status] || "Are you sure you want to delete this data?",
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder:
              { create: "#F7BC0B", edit: "#F7BC0B", success: "#28CC7D" }[
                status
              ] || "#E32A29",
            colorBtnConfirm:
              { create: "#F7BC0B", edit: "#F7BC0B", success: "#28CC7D" }[
                status
              ] || "#E32A29",
            colorBtnCancel: "#E0E0E0",
          }}
        />
      )}
      <Header parentmenu={"Master Data"} />
      <Menu parentmenu={"MasterData"} activemenu={"ResidentType"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดผู้อยู่อาศัย"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Resident ID</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Resident_ID"
                        placeholder="Resident ID"
                        value={data.Resident_ID}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Resident_ID: event.target.value,
                          })
                        }
                        disable={true}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Resident Name (EN)</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Resident_EN"
                        placeholder="Resident Name (EN)"
                        value={data.Resident_EN || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "en");
                        }}
                        disable={data.Resident_TH ? true : false}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Resident Name (TH)</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Resident_TH"
                        placeholder="Resident Name (TH)"
                        value={data.Resident_TH || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "th");
                        }}
                        disable={data.Resident_EN ? true : false}
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row>
                <Col md={6} xl={6}>
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_EN
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Resident.resident_en")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} xl={6}>
                  {" "}
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_TH
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Resident.resident_th")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {editId && (
                <>
                  <Row className="mt-3">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">2024-09-25 10:00</span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">Pichtira Siriwarin</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                  <Row className="mt-3">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">2024-09-25 10:00</span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">Pichtira Siriwarin</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                </>
              )}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("masterDataResidentType");
                  }}
                >
                  Back
                </button>{" "}
                <button
                  disabled={!data.Resident_EN && !data.Resident_TH}
                  className="btn-save"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
