import axios from "axios";
import Swal from "sweetalert2";
import "../components/swalStyle/customStyle.css";
import { getInstance } from "./baseapi";

const dataToken = localStorage.getItem("tokenBackoffice");

const URL = process.env;

const instance = getInstance();

export const fetchUserDataList = async (queryparams) => {
  try {
    const rs = await instance.get(`/user`, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
      params: queryparams,
    });

    if (rs.data.status.toLowerCase() == "success") {
      return rs.data.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    throw error;
  }
};
export const fetchProfileDataList = async (queryparams) => {
  try {
    const rs = await instance.get(`/user/admin/profile`, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
      params: queryparams,
    });

    if (rs.data.status.toLowerCase() == "success") {
      return rs.data.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUserById = async (id) => {
  try {
    const rs = await instance.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
    });
    if (rs.data.status.toLowerCase() == "success") {
      return rs.data.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    throw error;
  }
};
export const createUserData = async (body) => {
  try {
    const rs = await instance.post(`/user`, body, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
    });
    if (rs.data.status.toLowerCase() == "success") {
      return rs.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: error.response.data.message,
      confirmButtonText: "ตกลง",
    }).then((result) => {});

    throw error;
  }
};
export const updateUserData = async (id, body) => {
  try {
    const rs = await instance.put(`/user/${id}`, body, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("tokenBackoffice"),
      },
    });

    if (rs.data.status.toLowerCase() == "success") {
      return rs.data;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: error.response.data.message,
      confirmButtonText: "ตกลง",
    }).then((result) => {});

    throw error;
  }
};
