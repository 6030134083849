import React from "react";
import moment from "moment";

const DateDisplay = ({ date }) => {
  // Check if the date exists, then format it
  const formattedDate = date ? moment(date).format("YYYY-MM-DD h:mm ") : "-";

  return <div>{formattedDate}</div>;
};

export default DateDisplay;
