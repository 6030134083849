export const textLoginBtn = "ลงชื่อเข้าใช้";
export const textSearchBtn = "Search";
export const textResetBtn = "Clear";
export const textCreateBtn = "สร้าง";
export const textSaveBtn = "บันทึก";
export const textCancleBtn = "ยกเลิก";
export const textEditBtn = "แก้ไข";
// color
export const colorLoginBtn = "#281C9D";
export const colorSearchBtn = "#A3A3A3";
export const colorResetBtn = "#B3545A";
export const colorCreateBtn = "#03A9F4";
export const colorSaveBtn = "#5A85FB";
export const colorCancleBtn = "#DB000E";
export const colorUploadBtn = "#DB000E";
export const colorVerifyBtn = "#DB000E";
export const colorEditBtns = "#FF9800";
// icon
export const iconSearchBtn = "fas fa-search";
export const iconResetBtn = "fas fa-undo";
export const iconCreateBtn = "+";
// color shadow hover
export const hoverLoginBtn = "#281C9D";
export const hoverSearchBtn = "#716d6d";
export const hoverResetBtn = "#944146";
export const hoverCreateBtn = "#0481ba";
export const hoverSaveBtn = "#5076dd";
export const hoverCancleBtn = "#b1030e";
export const hoverUploadBtn = "#b1030e";
export const hoverVerifyBtn = "#b1030e";
export const hoverEditBtns = "#ef8f00";
