import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "src/components/swalStyle/customStyle.css";
import InputData from "src/components/Input"
import { useTranslation } from "react-i18next"
import { forgotValidator } from "src/validations/forgot-password/forgotValidator"
import { usePopup } from "src/hooks/usePopup"
import { forgotPasswordService } from "src/api/adminAuthRoutes"
function ForgotPassword() {
  const { t } = useTranslation()

  const { popup, setPopup } = usePopup({
    status: 'success',
    show: false,
    message: "",
    onConfirm: null,
    onCancel: null
  })

  const navigate = useNavigate();
  const [formData, setformData] = useState({ email: '' }); //nosonar

  const validateResult = forgotValidator(formData)
  const handleSubmit = async () => {
    const result = await forgotPasswordService(formData.email)
    try {
      if (result.status === 404) {
        setPopup({
          ...popup,
          show: true,
          status: 'error',
          message: result?.data?.message
        })
      } else {
        setPopup({
          ...popup,
          show: true,
          status: 'success',
          message: result?.message,
          onConfirm: () => navigate('/')
        })
      }
    } catch (error) {
      setPopup({
        ...popup,
        show: true,
        status: 'error',
        message: "error occur",
      })
      console.log(error)
    }
  }

  return (
    <div id='forgotPW' className='base'>
      <div className="container-fluid height100">
        <div className="row height100">
          <div className="col-12 col-lg-6 login01">
            {/* <img
                    src="assets/images/logo-login.png"
                    alt="Login Logo"
                    className="im-login01"
              /> */}
          </div>
          <div className="col-12 col-lg-6 bg-white d-flex justify-content-center align-items-center">
            <div className="row area01">
              <div className="col-12 col-lg-10 mt-3 mb-3 mx-auto d-flex justify-content-center">
                <label className="fontReg font-size05 line-height03 font-weight-bold colorBlue01">
                  {t('forgot_password.header')}
                </label>
              </div>
              <div className="sty-box01">
                <div className="col-12 col-lg-10 mx-auto">
                  <InputData
                    type="text"
                    onChange={
                      (e) => setformData({
                        ...formData,
                        email: e.target.value
                      })
                    }
                    value={formData?.email}
                    placeholder={t('forgot_password.email')}
                    id="email"
                    className="inp-login fontLight font-size03"
                  />
                </div>
                {
                  validateResult['email'] &&
                  <div className="col-12 col-lg-10 mx-auto">
                    <small className='fontLight font-size03 colorRed'>
                      {t(validateResult['email'])}
                    </small>
                  </div>
                }
                <div className="col-12 col-lg-10 mx-auto mt-3">
                  <button disabled={validateResult}
                    className="btn-green fontReg font-weight-bold font-size03"
                    onClick={() => handleSubmit()}
                  >
                    {t('forgot_password.submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword