import './Login.css'
import { useState, useCallback } from "react";
import { resetPasswordService } from "src/api/adminAuthRoutes"
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { resetPasswordValidator } from 'src/validations/reset-password/resetPasswordValidator';
import { usePopup } from 'src/hooks/usePopup';

function ChangePassword() {
  const { popup, setPopup } = usePopup({
    status: 'success',
    show: false,
    message: "",
    onConfirm: null,
    onCancel: null
  })

  const { t } = useTranslation()
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [formData, setFormData] = useState({ password: '', newpassword: '' })

  async function handleClickConfirm() {
    setPopup({
      ...popup,
      show: true,
      status: 'warning',
      showCancel: true,
      message: t('popup.confirm'),
      confirmBtnText: 'Yes',
      onConfirm: () => sendConfirmPassword()
    })
  }

  const navigate = useNavigate();
  const { token } = useParams()

  async function sendConfirmPassword() {
    try {
      const response = await resetPasswordService(
        token,
        formData.newpassword
      )
      
      if (response?.status === 'success') {
        setPopup({
          ...popup,
          show: true,
          status: 'success',
          message: t('popup.success'),
          showCancel: false,
          onConfirm: () => navigate('/')
        })
      }
      else {
        setPopup({
          ...popup,
          show: true,
          status: 'delete',
          message: t('popup.failed'),
          confirmBtnText: 'Ok',
          showCancel: false,
          onConfirm: () => {
            setPopup({
              ...popup,
              show: false
            })
          }
        })
      }
    } catch (error) {
      setPopup({
        ...popup,
        show: true,
        status: 'delete',
        showCancel: false,
        message: t('popup.failed'),
        confirmBtnText: 'Ok',
        onConfirm: () => {
          setPopup({
            ...popup,
            show: false
          })
        }
      })
    }
  }

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);

  const validateResult = resetPasswordValidator(formData)

  return (
    <div id="changePassword" className="base">
      <div className="container-fluid height100">
        <div className="row height100">
          <div className="col-12 col-lg-6 login01">
            {/* <img
                    src="assets/images/logo-login.png"
                    alt="Login Logo"
                    className="im-login01"
              /> */}
          </div>
          <div className="col-12 col-lg-6 bg-white d-flex justify-content-center align-items-center">
            <div className="row area01">
              <div className="col-12 mt-3 mb-3 d-flex justify-content-center">
                <label className="fontReg font-size05 line-height03 font-weight-bold colorBlue01">
                  {t('reset_password.header')}
                </label>
              </div>
              <div className="sty-box01">
                <div className="col-12">
                  <label className="fontLight font-size03">{t('reset_password.new_password')}</label>
                  <div className="d-flex justify-content-between box-pass">
                    <input
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      type={visible1 ? "text" : "password"}
                      className="inp-pass fontLight font-size03"
                      id="new_password"
                      placeholder={t('reset_password.new_password_hint')}
                      autoComplete="on"
                    />
                    <div className="p-2" onClick={() => setVisible1(!visible1)}>
                      {
                        visible1 ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    <small className="font-size03 fontReg colorRed">{validateResult?.password}</small>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <label className="fontLight font-size03">
                    {t('reset_password.re_new_password')}
                  </label>
                  <div className="d-flex justify-content-between box-pass">
                    <input
                      name="newpassword"
                      value={formData.newpassword}
                      onChange={handleChange}
                      type={visible2 ? "text" : "password"}
                      className="inp-pass fontLight font-size03"
                      id="confirm_password"
                      placeholder={t('reset_password.re_new_password')}
                      autoComplete="on"
                    />
                    <div className="p-2" onClick={() => { setVisible2(!visible2) }}>
                      {
                        visible2 ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    <small className="font-size03 fontReg colorRed">{validateResult?.newpassword}</small>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <button
                    onClick={async () => { await handleClickConfirm() }}
                    className="btn-green fontReg font-weight-bold font-size03"
                  >
                    {t('reset_password.submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword